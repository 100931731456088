// /* eslint-disable react/prop-types */
// import React from 'react';

// const IconPath = ({ stroke = '#fff', fill = '#51c43c' }) => (
//   <svg viewBox="1 0 20 20">
//     <g data-name="Group 5134" transform="translate(-846 -12299)">
//       <rect
//         width="20"
//         height="20"
//         fill={fill}
//         rx="10"
//         transform="translate(847 12299)"
//       />
//       <path
//         fill="none"
//         stroke={stroke}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth="2"
//         d="M854.082 12309.686l1.677 1.676 4.958-4.954"
//       />
//     </g>
//   </svg>
// );

/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = ({ stroke = '#ACB5C7', fill = 'none' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
    <path
      d="M5 11.2L7.8 14L15.8 6"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconPath;
