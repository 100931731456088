import React from 'react';
import { List } from './List';
import { Menu, Main, Provider } from '../Layout';

const Clients = () => {
  return (
    <Provider>
      <Menu />
      <Main>
        <List />
      </Main>
    </Provider>
  );
};

export { Clients };
