import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Grid } from '../../common/Grid';
import { Button, Heading } from '../../common';
import { Text } from '../../common/Text';
import { Menu, Provider, Main } from '../Layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(28, 17, 44, 0.1);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 16px;
`;
const Dashboard = () => {
  const history = useHistory();

  const onNavigateClients = () => history.push('/clients');
  const onNavigateUsers = () => history.push('/users');
  const onNavigateStart = () => history.push('/start');

  return (
    <Provider>
      <Menu />
      <Main>
        <Container>
          <Grid>
            <Row>
              <Col sm="3" md="4">
                <Heading>Dashboard</Heading>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6" md="4">
                <Tile>
                  <Text
                    type="p"
                    color="#606165"
                    lineHeight="26px"
                    weight="400"
                    verticalPull="16px"
                  >
                    View and manage your active or inactive clients. View jobs,
                    and assign jobs here.
                  </Text>
                  <Button onClick={onNavigateClients}>Your Clients</Button>
                </Tile>
              </Col>
              <Col xs="12" sm="6" md="4">
                <Tile>
                  <Text
                    type="p"
                    color="#606165"
                    lineHeight="26px"
                    weight="400"
                    verticalPull="16px"
                  >
                    View and manage your employees. See their hours, kilometers
                    driven and more.
                  </Text>
                  <Button onClick={onNavigateUsers}>Your Employees</Button>
                </Tile>
              </Col>
              <Col xs="12" sm="6" md="4">
                <Tile>
                  <Text
                    type="p"
                    color="#606165"
                    lineHeight="26px"
                    weight="400"
                    verticalPull="16px"
                  >
                    See what your employees see, use this to see how the
                    application works for them.
                  </Text>
                  <Button onClick={onNavigateStart}>View As</Button>
                </Tile>
              </Col>
            </Row>
          </Grid>
        </Container>
      </Main>
    </Provider>
  );
};

export { Dashboard };
