import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const loaderSpinAnimation = () => {
  return keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(720deg);
    }
`;
};

const loaderDashAnimation = () => {
  return keyframes`
    0% {
        stroke-dashoffset: 120;
        color: white;
    }
    50% {
      stroke-dashoffset: 282;
      color: #05cedd;
      }
    100% {
      stroke-dashoffset: 120;
      color: white;
    }
  `;
};

const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
  left: 50%;
  top: 50%;
  position: fixed;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const LoaderItem = styled.div`
  ${props =>
    props.width &&
    css`
      width: ${props.width || '32px'};
      height: ${props.height || ' 32p'}x;
    `}

  ${props =>
    props.center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
    `}
    ${props =>
    props.stroke &&
    css`
      circle {
        stroke: ${props.stroke};
        stroke-width: ${props.strokeWidth};
      }
    `}
  ${props =>
    props.variant === 'regular' &&
    css`
      margin: 0;
      padding: 0;
      width: 32px;
      height: 32px;
      animation: ${loaderSpinAnimation} 1.5s ease-out infinite;
      border-radius: 100%;
      transform-origin: center;
      circle {
        stroke-width: ${props => props.strokeWidth || 14};
        stroke-linecap: round;
        stroke-dasharray: 282;
        stroke: currentColor;
        fill: transparent;
        animation: ${loaderDashAnimation} 1.5s linear infinite;
        width: 100%;
        height: 100%;
      }
    `}
`;

const Loader = ({
  variant = 'regular',
  width = 32,
  height = 32,
  stroke = '#05cedd',
  viewBox = '0 0 90 90',
  strokeWidth = 4,
  ...props
}) => {
  return (
    <LoaderContainer>
      <LoaderItem
        stroke={stroke}
        strokeWidth={strokeWidth}
        variant={variant}
        height={height}
        center={props.center}
      >
        <svg width={width} height={height} viewBox={viewBox} {...props}>
          <circle cx="45" cy="45" r="38" />
        </svg>
      </LoaderItem>
    </LoaderContainer>
  );
};

export { Loader };
