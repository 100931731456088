import React from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { Icon } from '../../../../common';

import { addUserColor } from '../../../../state/user/reducers';

const ColorGroup = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
`;

const ColorItem = styled.li`
  border-radius: 8px;
  overflow: hidden;
  height: 50px;
  width: 50px;
  padding: 0;
  margin: 0;
  margin: 0px 2px;
  border: 1px solid #e3e5eb;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:nth-child(1) {
    margin-left: 0px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0;
    padding: 0;
    display: flex;
  }
  ${props =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}
  ${props =>
    props.isSelected &&
    css`
      border: 1px solid black;
      box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2);
    `}
`;

const ColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ColorPicker = ({ uid, color = '' }) => {
  const colors = [
    { label: 'yellow', value: '#f0ca43' },
    { label: 'green', value: '#73d673' },
    { label: 'red', value: '#ee4d4d' },
    { label: 'blue', value: '#17318f' },
    { label: 'purple', value: '#8516b3' },
    { label: 'grey', value: '#e0e0e0' },
  ];

  const dispatch = useDispatch();

  const updateUserColor = selectedColor => {
    if (uid && selectedColor) {
      dispatch(addUserColor({ uid, color: selectedColor }));
    }
  };

  const renderColors = colors.map(t => (
    <ColorItem
      key={t.label}
      isSelected={t.value === color}
      onClick={() => updateUserColor(t.value)}
      className={t.value === color ? 'isActive' : ''}
      bgColor={t.value}
    >
      {t.value === color && (
        <span>
          <Icon name="CHECK" size={20} stroke={`#fff`} />
        </span>
      )}
    </ColorItem>
  ));

  return (
    <ColorContainer>
      <ColorGroup>{renderColors}</ColorGroup>
    </ColorContainer>
  );
};

export { ColorPicker };
