import React, { useRef, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { useClickOutside as ClickOutside } from '../../utils/hooks/useClickOutside';
import { Icon } from '../Icon';
import {
  ModalContainer,
  ModalContent,
  ModalCloseButton,
  ModalTitle,
  ModalBlock,
} from './Modal.styled';

const Portal = ({ children, selector }) => {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, ref.current) : null;
};

const configLevel = {
  1: 100,
  2: 200,
  3: 300,
  9: 999,
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 42, 67, 0.24);
  display: none;
  ${props =>
    props.level &&
    css`
      z-index: ${configLevel[props.level] || 100};
    `}
  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
  ${props =>
    props.dev &&
    css`
      z-index: 0;
      position: relative;
    `}
`;

const Modal = ({ children, ...props }) => {
  const { type, title, callback } = props;
  const onClose = () => {
    callback('CLOSED');
  };

  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      callback('CLOSED');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const renderWrapper = () => (
    <>
      <ClickOutside callback={onClose} style={{ marginRight: '0px' }}>
        {type === 'PRIMARY' && (
          <ModalContainer>
            <ModalContent>
              {title && (
                <ModalBlock>
                  {title && <ModalTitle>{title}</ModalTitle>}
                  <ModalCloseButton onClick={() => callback('CLOSED')}>
                    <Icon name="CLOSE" size={20} />
                  </ModalCloseButton>
                </ModalBlock>
              )}
            </ModalContent>
            {children}
          </ModalContainer>
        )}
        {children}
      </ClickOutside>
      <Overlay level={1} isActive />
    </>
  );

  return <Portal selector="#portal">{renderWrapper()}</Portal>;
};

Modal.defaultProps = {
  reset: false,
  title: '',
  callback: () => {},
  type: 'PRIMARY',
};

export { Modal };
