/* eslint-disable react/prop-types */
import React from 'react';

const create = props => {
  const { fill = 'none', stroke = '#6a7a8d' } = props;
  return (
    <svg viewBox="0 0 14 14">
      <g
        id="Group_4096"
        data-name="Group 4096"
        transform="translate(6739.5 -2788.5)"
      >
        <line
          id="Line_1535"
          data-name="Line 1535"
          x1="12"
          transform="translate(-6738.5 2795.5)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_1536"
          data-name="Line 1536"
          y1="12"
          transform="translate(-6732.5 2789.5)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default create;
