/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.75 4C3.75 2.78283 4.78116 1.75 6.11111 1.75H10.1296C10.3141 1.75 10.4167 1.8874 10.4167 2V4.625C10.4167 6.16926 11.707 7.375 13.2407 7.375H15.963C16.1474 7.375 16.25 7.5124 16.25 7.625V16C16.25 17.2172 15.2188 18.25 13.8889 18.25H6.11111C4.78116 18.25 3.75 17.2172 3.75 16V4Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <line
        x1="6.75"
        y1="11.25"
        x2="9.25"
        y2="11.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="6.75"
        y1="14.25"
        x2="11.25"
        y2="14.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2828 1.77808L16.2109 7.49219"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPath;
