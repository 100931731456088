import React from 'react';

const Logo = ({ width = 38, height = 38 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none"></svg>
  );
};

Logo.displayName = 'LogoIcon';

export default Logo;
