import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Loader } from '../../common';
import { Input, Button, ErrorList } from '../../common';

import { media } from '../../utils/styles';

import { useRouter } from '../../utils/hooks/useRouter';
import { loginUser } from '../../state/auth/reducers';
import { authSelector } from '../../state/auth/selectors';

import { useHistory } from 'react-router-dom';

const LoginContainer = styled.div`
  padding: 24px;
  border: 1px solid #e3e5eb;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  width: 100%;
  position: relative;
  margin-top: 64px;
  ${media.sm`
    max-width: 600px;
  `}
`;

const FormContainer = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;

const { user, loading, persisted, authenticated } = authSelector;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const router = useRouter();
  const [errors, setList] = useState([]);

  const history = useHistory();

  const isPersisted = useSelector(persisted);
  const isLoading = useSelector(loading);
  const userObject = useSelector(user);
  const isAuthenticated = useSelector(authenticated);

  const onChangeEmail = e => {
    const { value } = e.target;
    setEmail(value);
  };

  const onDev = () => {
    setEmail('philiphultgren7@gmail.com');
    setPassword('Password1!');
  };

  const onChangePassword = e => {
    const { value } = e.target;
    setPassword(value);
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!email || !password) {
      setList([
        {
          name: 'error',
          message: 'Please enter a valid email and password.',
        },
      ]);
    } else {
      dispatch(loginUser({ email, password }));
    }
  };

  const removeItem = name => {
    setList(errors.filter(item => item.name !== name));
  };

  const renderErrors = errors => {
    if (!errors || errors.length <= 0) return null;
    return (
      <ErrorList
        haystack={errors}
        callback={removeItem}
        hasClose={true}
        needles={['error']}
        style={{ marginTop: '16px', marginBottom: '0' }}
      />
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      // history.push('/users');
      history.push('/');
    }
  }, [isAuthenticated]);

  return (
    <>
      {isLoading && <Loader />}

      <LoginContainer>
        <FormContainer onSubmit={onSubmit}>
          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={onChangeEmail}
          />
          <Input
            type="text"
            placeholder="Password"
            value={password}
            verticalPush={16}
            onChange={onChangePassword}
          />
          <Button
            variant="OUTLINE"
            verticalPush={16}
            style={{ width: '100%', textAlign: 'center' }}
            onClick={onSubmit}
          >
            Login
          </Button>
          {renderErrors(errors)}
          <Button variant="PRIMARY" verticalPush={16} onClick={onDev}>
            Force Login
          </Button>
        </FormContainer>
      </LoginContainer>
    </>
  );
};

export { Login };
