import React from 'react';
import { useDispatch } from 'react-redux';
import { addPinById } from '../../../../state/user/reducers';
import { Pin } from '../../../../common';
import styled from 'styled-components';

const PinContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
`;

const PinManager = ({ uid, pin }) => {
  const dispatch = useDispatch();

  const onUpdatePin = pin => {
    dispatch(addPinById({ pin, uid }));
  };

  return (
    <PinContainer>
      <Pin value={pin} callback={onUpdatePin} />
    </PinContainer>
  );
};

export { PinManager };
