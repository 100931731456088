const theme = {
  colors: {
    orange: '#EF6C4D',
    grapefruit: '#EB3D2B',
    blueberry: '#5186EC',
    passionfruit: '#6C20CC',
    grape: '#BE8BFF',
    charcoal: '#1B1A29',
    ocean: '#1D2A43',
    greyXXXDark: '#5d646f',
    greyXXDark: '#8B8E94',
    greyXDark: '#B3B6BC',
    grey: '#BEC2CF',
    greyLight: '#D7DAE4',
    greyXLight: '#EDEEEF',
    greyXXLight: '#F6F5FC',
  },
};

export { theme };
