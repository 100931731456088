/* eslint-disable react/prop-types */
import React from 'react';

const Icon = props => {
  const { fill = '#ffffff' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5243 8.50057C17.6249 9.03511 17.6793 9.59592 17.6793 10.1803C17.6793 14.7502 14.6197 18 10 18C5.58052 18 2 14.4195 2 10C2 5.58052 5.58052 2 10 2C12.1599 2 13.9647 2.79456 15.3499 4.08471L13.0949 6.33975V6.33431C12.255 5.53431 11.1905 5.1239 10 5.1239C7.35991 5.1239 5.2154 7.35357 5.2154 9.99366C5.2154 12.6328 7.36082 14.8689 10 14.8689C12.3955 14.8689 14.0245 13.499 14.3606 11.619H10V8.49875L17.5243 8.50057Z"
        fill={fill}
      />
    </svg>
  );
};

export default Icon;
