import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
// FEATURES
import { Clients, Client } from './features/Clients';
import { Users } from './features/Users';
import { Login } from './features/Login';
import { Logout } from './features/Logout';
import { Dashboard } from './features/Dashboard';

import { useDispatch } from 'react-redux';
// STATE
import { authSelector } from './state/auth/selectors';
import { persistUser } from './state/auth/reducers';

import { useSelector } from 'react-redux';

const { authenticated } = authSelector;

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = useSelector(authenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(persistUser());
  }, []);

  return (
    <Route
      {...restOfProps}
      render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const App = () => (
  <HashRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/users" component={Users} />
      <ProtectedRoute exact path="/clients" component={Clients} />
      <Route path="/clients/:number" component={Client} />
    </Switch>
  </HashRouter>
);

export default App;
