import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 16px;
  background: #fafafb;
  flex-direction: column;
  overflow: auto;
  position: relative;
  min-height: 100vh;
  ${media.sm`
  padding: 32px;
  `}
`;

const Main = ({ children }) => {
  return <MainContainer>{children}</MainContainer>;
};

export { Main };
