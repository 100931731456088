import React from 'react';
import CalendarComponent from 'react-calendar';
import styled from 'styled-components';

const CalendarContainer = styled.div`
  margin-bottom: 16px;
  min-width: 300px;
  overflow: hidden;

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 2px solid transparent;

    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;

    line-height: 1.125em;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    background: #ffffff;
    /* Base/Stroke */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e5eb;
    box-sizing: border-box;
    /* Small Shadow */
    width: 24px;
    height: 24px;
    line-height: 31px;
    box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
    border-radius: 6px;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    background: #ffffff;
    /* Base/Stroke */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e5eb;
    box-sizing: border-box;
    /* Small Shadow */
    width: 24px;
    height: 24px;
    line-height: 31px;
    box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
    border-radius: 6px;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .react-calendar__navigation button {
    min-width: 24px;
    background: none;
    height: 100%;
  }
  .react-calendar__navigation__label {
    &.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    /* background-color: #e6e6e6; */
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-size: 12px;
    font-weight: 400;
    color: #a9aeb9;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  .react-calendar__navigation {
  }
  .react-calendar__navigation__label {
    height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    /* box-shadow: 0px 1px 2px rgb(28 17 44 / 5%); */
    border: 1px solid #e3e5eb;
  }
  .react-calendar__month-view__days {
    border-radius: 6px;
    overflow: hidden;
  }
  .react-calendar__month-view__weekdays {
    div {
      padding: 10px;
    }
    abbr {
      text-decoration: none;
    }
  }
  button {
    &.react-calendar__tile {
      border-radius: 3px;
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
        background: #4b658d;
        color: white;
      }
      &.react-calendar__tile--active {
        background: #4b658d;
        color: white;
      }
    }
  }
  button {
    &.react-calendar__tile {
      border: none;
      padding: 10px;
    }
  }
  button {
    &.react-calendar__tile,
    &.react-calendar__month-view__days__day,
    &.react-calendar__month-view__days__day--weekend {
      &:disabled {
        &:hover {
          background: white;
          cursor: not-allowed;
        }
        background: white;
        abbr {
          color: #b4b4b4;
        }
      }
    }
  }
`;

const Calendar = ({ className, ...props }) => (
  <CalendarContainer className={className}>
    <CalendarComponent {...props} />
  </CalendarContainer>
);

export { Calendar };
