/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.4477 2L5 2C3.34315 2 2 3.34315 2 5V15C2 16.6569 3.34315 18 5 18H15C16.6569 18 18 16.6568 18 15C18 12.8768 18 11.5907 18 9.07943"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29296 13.238L6.8867 10.5914L15.2512 2.22689C15.8652 1.61291 16.8529 1.58876 17.4962 2.17202C17.8981 2.53646 18.1684 3.00365 18.2524 3.43405C18.332 3.84216 18.2459 4.19071 17.9748 4.46448L9.34648 12.7321L6.53367 13.4702C6.39177 13.5075 6.26085 13.3812 6.29296 13.238Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.95984 12.6985L6.99995 9.7386"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPath;
