/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="18.25"
        y="18.25"
        width="16.5"
        height="16.5"
        rx="1.25"
        transform="rotate(-180 18.25 18.25)"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M10 6.5L10 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.64645 3.85355L6.85355 6.64645C6.53857 6.96143 6.76165 7.5 7.20711 7.5L12.7929 7.5C13.2383 7.5 13.4614 6.96143 13.1464 6.64645L10.3536 3.85355C10.1583 3.65829 9.84171 3.65829 9.64645 3.85355Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
