import React, { useState, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { prop } from 'ramda';

import { Entries } from './Entries';
import { Totals } from './Totals';
import { Uploader } from './Uploader';
import { Controls } from './Controls';
import { Blade } from './Blade';
import { PinManager } from './PinManager';
import { DateRange } from './DateRange';
import { ColorPicker } from './ColorPicker';
import { RemoveUser } from './RemoveUser';
import { Calendar } from './Calendar';

import { media } from '../../../utils/styles';
import { uuid } from '../../../utils/uuid';
import { format, addDays } from 'date-fns/fp';
import { Loader, Icon, Button, Text, Input } from '../../../common';

import {
  fetchUsers,
  addEntry,
  removeEntry,
} from '../../../state/user/reducers';
import { userSelector } from '../../../state/user/selectors';
import { fetchSettings } from '../../../state/settings/reducers';

const UserGroup = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserContainer = styled.div`
  padding: 0px;
`;

const ExpanderHeader = styled.div`
  background: #ffffff;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  border-bottom: 1px solid transparent;
  margin-top: 0;
  ${props =>
    props.isActive &&
    css`
      border-bottom: 1px solid #e3e5eb;
    `}
`;

const ExpanderTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: black;
  text-transform: capitalize;
  padding: 8px 0px;
`;

const ExpanderContent = styled.div`
  background-color: white;
  transition: max-height 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
`;

const ExpanderSettings = styled.button`
  padding: 0;
  margin: 0;
  flex-grow: 1;
  background: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    border: 1px solid #e3e5eb;
  }
`;

const ExpanderImage = styled.div`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  min-width: 32px;
  margin-right: 16px;
  ${props =>
    props.imageSrc &&
    css`
      background-image: ${`url(${props.imageSrc})`};
    `}
  ${props =>
    props.imageDefault &&
    css`
      background-color: #e0e0e0;
    `}
`;

const ExpanderButton = styled.button`
  background: #ffffff;
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const HeadingLine = styled.h2`
  line-height: 24px;
  margin: 0;
  margin-bottom: 16px;
  margin-top: 16px;
  border-top: 1px solid #e0e0e0;
  padding-top: 16px;
  font-weight: 400;
  font-size: 18px;
  color: #1d2a43;
`;

const ExpanderItem = styled.div`
  margin-top: 16px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  &:first-child {
    margin-top: 0;
  }
`;

const ExpanderWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.03);
`;

const CreateEntryContainer = styled.div`
  padding: 16px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  padding-top: 16px;
  label {
    margin-bottom: 16px;
  }
  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .NumberInput {
    flex: 1;
    margin-right: 8px;
  }
`;

const TimeInput = styled.input.attrs({ type: 'time' })`
  padding: 9px;
  border-radius: 4px;
  border: none;
  transition: all 0.1s ease-in-out;
  width: 100%;
  outline: none;
  color: #a9aeb9;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #f7f8fa;
  background: #f7f8fa;
  &:focus {
    border: 1px solid #4b658d;
    outline: none;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid #4b658d;
  }
`;

const CreateEntry = ({ uid }) => {
  const dispatch = useDispatch();

  const [localKilometers, setLocalKilometers] = useState('');
  const [localBreakHourTime, setLocalBreakHourTime] = useState('');
  const [localBreakMinuteTime, setLocalBreakMinuteTime] = useState('');
  const [localStartTime, setLocalStartTime] = useState('00:00');
  const [localEndTime, setLocalEndTime] = useState('00:00');
  const [localDate, setLocalDate] = useState(new Date());

  const handleStartChange = e => {
    const { value } = e.target;
    setLocalStartTime(value);
  };

  const handleEndChange = e => setLocalEndTime(e.target.value);

  const onSubmitEntry = () => {
    dispatch(
      addEntry({
        uid,
        item: {
          id: uuid(),
          date: format('yy-MM-dd')(localDate),
          start: localStartTime,
          end: localEndTime,
          breakHour: localBreakHourTime,
          breakMinute: localBreakMinuteTime,
          kilometers: localKilometers,
          createdBy: 'admin',
          jobId: 'none',
        },
      }),
    );
  };

  const handleKilometers = e => {
    const { value } = e.target;
    setLocalKilometers(value);
  };

  const handleBreakHourTime = e => {
    const { value } = e.target;
    setLocalBreakHourTime(value);
  };

  const handleBreakMinuteTime = e => {
    const { value } = e.target;
    setLocalBreakMinuteTime(value);
  };

  const onChangeDate = date => {
    setLocalDate(date);
  };

  const getSevenDays = addDays(7)(new Date());

  return (
    <CreateEntryContainer>
      <HeadingLine>Add Entry</HeadingLine>
      <Calendar
        className="date"
        onChange={onChangeDate}
        value={localDate}
        maxDate={getSevenDays}
      />
      <FieldGroup>
        <TimeInput value={localStartTime} onChange={handleStartChange} />
      </FieldGroup>
      <FieldGroup>
        <TimeInput value={localEndTime} onChange={handleEndChange} />
      </FieldGroup>
      <FieldGroup>
        <Input
          type="text"
          placeholder="Kilometers"
          value={localKilometers}
          onChange={handleKilometers}
        />
      </FieldGroup>
      <FieldGroup>
        <Input
          type="text"
          placeholder="Break Time Hours"
          value={localBreakHourTime}
          onChange={handleBreakHourTime}
        />
      </FieldGroup>
      <FieldGroup>
        <Input
          type="text"
          placeholder="Break Time Minutes"
          value={localBreakMinuteTime}
          onChange={handleBreakMinuteTime}
        />
      </FieldGroup>
      <Button
        variant="PRIMARY"
        onClick={onSubmitEntry}
        style={{
          justifyContent: 'center',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <span>Create</span>
      </Button>
    </CreateEntryContainer>
  );
};

const UserItem = ({ ...item }) => {
  const dispatch = useDispatch();
  const [isExpanded, setExpanded] = useState(false);
  const [isBladeExpanded, setBladeExpanded] = useState(false);
  const [bladeContent, setBladeContent] = useState('SETTINGS');
  const [selectedEntry, setSelectedEntry] = useState(null);

  const { loading } = userSelector;
  const isLoading = useSelector(loading);

  useEffect(() => {
    setSelectedEntry(null);
    setBladeExpanded(false);
  }, [isLoading]);

  const onSelect = (item, index) => {
    setBladeContent('ENTRY');
    setSelectedEntry({ ...item, index });
    setBladeExpanded(true);
  };

  const handleExpand = () => setExpanded(!isExpanded);

  const onPressSettings = value => {
    if (!isExpanded) {
      setBladeExpanded(true);
      setBladeContent('SETTINGS');
      setExpanded(true);
      return;
    }
    setBladeExpanded(true);
    setBladeContent('SETTINGS');
  };

  const onClose = () => {
    setBladeExpanded(false);
  };

  const title = prop('firstname')(item);
  const image = prop('image')(item);

  const contentRef = useRef(null);
  const [height, setHeightState] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        setHeightState(contentRef.current.scrollHeight);
      } else {
        setHeightState(0);
      }
    }
  }, [contentRef.current, isExpanded]);

  const renderSettings = ({ ...item }) => (
    <>
      <HeadingLine>User Image</HeadingLine>
      <Uploader {...item} />
      <HeadingLine>Choose Theme</HeadingLine>
      <ColorPicker {...item} />
      <HeadingLine>Reset Pin</HeadingLine>
      <PinManager {...item} />
      <HeadingLine style={{ marginTop: 'auto' }}>Danger Zone</HeadingLine>
      <RemoveUser {...item} />
    </>
  );

  const handleRemoveEntry = ({ uid, id }) => {
    dispatch(removeEntry({ id, uid }));
  };

  const renderEntryBlade = () => {
    if (!selectedEntry || !selectedEntry.active) {
      return (
        <>
          <HeadingLine>Date</HeadingLine>
          <Text color="#778092">{selectedEntry && selectedEntry.date}</Text>
        </>
      );
    }
    return (
      <>
        <HeadingLine>Date</HeadingLine>
        <Text color="#778092">{selectedEntry && selectedEntry.date}</Text>
        <HeadingLine>Kilometers</HeadingLine>
        <Text color="#778092">{selectedEntry.kilometers || 0}</Text>
        <HeadingLine>Break Time</HeadingLine>
        <Text color="#778092">{selectedEntry.breakHour || '0'}</Text>
        <HeadingLine>Break Minutes</HeadingLine>
        <Text color="#778092">{selectedEntry.breakMinute || '0'}</Text>
        <HeadingLine>Job Id</HeadingLine>
        <Text color="#778092">{selectedEntry.jobId || 'None provided'}</Text>
        <HeadingLine>Start Time</HeadingLine>
        <Text color="#778092">{selectedEntry.start || '00:00'}</Text>
        <HeadingLine>Knocked off Time</HeadingLine>
        <Text color="#778092">{selectedEntry.end || '00:00'}</Text>
        <HeadingLine>Danger Zone</HeadingLine>
        <Button
          variant="WARNING"
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '8px',
          }}
          onClick={() =>
            handleRemoveEntry({ uid: item.uid, id: selectedEntry.id })
          }
        >
          Remove Entry
        </Button>
      </>
    );
  };

  const renderBlade = (
    <Blade title={`Entry`} isActive={isBladeExpanded} onClose={onClose}>
      {bladeContent === 'SETTINGS' && renderSettings({ ...item })}
      {bladeContent === 'ENTRY' && renderEntryBlade()}
    </Blade>
  );

  return (
    <ExpanderItem>
      <ExpanderWrapper>
        <ExpanderHeader isActive={isExpanded}>
          <ExpanderButton onClick={handleExpand}>
            {image ? (
              <ExpanderImage imageSrc={image} />
            ) : (
              <ExpanderImage imageDefault />
            )}
            {title && <ExpanderTitle>{title}</ExpanderTitle>}
            <Totals {...item} />
          </ExpanderButton>
          <ExpanderSettings onClick={() => onPressSettings(true)}>
            <Icon name="SETTINGS" size={20} stroke="#778092" />
          </ExpanderSettings>
        </ExpanderHeader>
        {renderBlade}
        <ExpanderContent
          ref={contentRef}
          style={{ maxHeight: `${height}px` }}
          className="expander"
        >
          <Entries
            callback={onSelect}
            selectedEntry={selectedEntry}
            {...item}
          />

          <CreateEntry {...item} />
        </ExpanderContent>
      </ExpanderWrapper>
    </ExpanderItem>
  );
};

const UsersList = () => {
  const dispatch = useDispatch();

  const { list } = userSelector;

  const users = useSelector(list);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  if (!users || users.length <= 0) return null;
  return (
    <UserGroup>
      {users.map((item, index) => (
        <React.Fragment key={`${index}-${uuid()}`}>
          <UserItem {...item} />
        </React.Fragment>
      ))}
    </UserGroup>
  );
};

const List = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { loading } = userSelector;

  const isLoading = useSelector(loading);

  const onPressSettings = () => {
    setIsOpen(!isOpen);
  };

  const onToggle = value => {
    setIsOpen(value);
  };

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  return (
    <UserContainer>
      <Controls callback={onPressSettings} isOpen={isOpen} />
      <DateRange
        isActive={isOpen}
        onClose={onPressSettings}
        onToggle={onToggle}
      />
      <UsersList />
      {isLoading && <Loader />}
    </UserContainer>
  );
};

export { List };
