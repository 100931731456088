export const STEP_SELECT_DATE = 'STEP_SELECT_DATE';
export const STEP_SELECT_START = 'STEP_SELECT_START';
export const STEP_SELECT_END = 'STEP_SELECT_END';
export const STEP_SELECT_BREAK = 'STEP_SELECT_BREAK';
export const STEP_SELECT_KILOMETERS = 'STEP_SELECT_KILOMETERS';
export const STEP_SELECT_SUBMIT = 'STEP_SELECT_SUBMIT';

export const STEP_SELECT_UPDATE = 'STEP_SELECT_UPDATE';
export const STEP_SELECT_RESET = 'STEP_SELECT_RESET';

const InitialState = {
  menuExpanded: false,
  bladeExpanded: false,
  entryStep: STEP_SELECT_DATE,
  entry: {
    day: '',
    date: '',
    start: '',
    end: '',
    breakHour: '',
    breakMinute: '',
    kilometers: '',
  },
};

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case 'OPEN_MENU':
      return {
        ...state,
        menuExpanded: true,
      };
    case 'CLOSE_MENU':
      return {
        ...state,
        menuExpanded: false,
      };
    case 'CLOSE_BLADE':
      return {
        ...state,
        bladeExpanded: false,
      };
    case 'OPEN_BLADE':
      return {
        ...state,
        bladeExpanded: true,
      };
    case STEP_SELECT_DATE:
      return {
        ...state,
        entry: {
          ...state.entry,
          date: data.date,
          day: data.day,
        },
      };
    case STEP_SELECT_START:
      return {
        ...state,
        entry: {
          ...state.entry,
          start: data,
        },
      };
    case STEP_SELECT_END:
      return {
        ...state,
        entry: {
          ...state.entry,
          end: data,
        },
      };
    case STEP_SELECT_BREAK:
      return {
        ...state,
        entry: {
          ...state.entry,
          breakHour: data.breakHour,
          breakMinute: data.breakMinute,
        },
      };
    case STEP_SELECT_KILOMETERS:
      return {
        ...state,
        entry: {
          ...state.entry,
          kilometers: data,
        },
      };
    case STEP_SELECT_UPDATE:
      return {
        ...state,
        entryStep: data,
      };
    case STEP_SELECT_RESET:
      return {
        ...state,
        entryStep: STEP_SELECT_DATE,
        entry: {
          day: '',
          date: '',
          start: '',
          end: '',
          breakHour: '',
          breakMinute: '',
          kilometers: '',
        },
      };
    default:
      return state;
  }
};

export { InitialState };

export default Reducer;
