import styled, { css } from 'styled-components';
import {
  horizontalPushFn,
  horizontalPullFn,
  verticalPullFn,
  verticalPushFn,
} from '../../utils/styles';

const basicStyles = css`
  display: flex;
  flex-direction: row;
  border: none;
  box-sizing: border-box;
`;

const basicText = css`
  line-height: 14px;
  font-size: 14px;
`;

const pseudoStyles = css`
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const basicFunctions = css`
  ${props =>
    props.isDisabled &&
    css`
      color: #a9aeb9 !important;
      background: #f7f7f9 !important;
      border: 1px solid #f7f7f9 !important;
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
        color: #a9aeb9 !important;
        background: #f7f7f9 !important;
        border: 1px solid #f7f7f9 !important;
        box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2) !important;
      }
      &:focus {
        cursor: not-allowed;
        box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2) !important;
      }
    `}
`;

const Primary = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${horizontalPushFn};
  ${horizontalPullFn};
  ${verticalPullFn};
  ${verticalPushFn};
  padding: 17px 16px;
  background: #4b658d;
  /* background: #ff5a45; */
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: white;
  &:hover {
    /* background: #ff5a45; */
    background: #0d2241;
    box-shadow: 0px 2px 8px rgba(209, 48, 28, 0.2),
      0px 5px 12px rgba(209, 48, 28, 0.06);
  }
`;

const Secondary = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${horizontalPushFn};
  ${horizontalPullFn};
  ${verticalPullFn};
  ${verticalPushFn};
  padding: 17px 16px;
  background: #ff5a45;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: white;
  &:hover {
    background: #ff5a45;
    box-shadow: 0px 2px 8px rgba(209, 48, 28, 0.2),
      0px 5px 12px rgba(209, 48, 28, 0.06);
  }
`;

const Quaternary = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${horizontalPushFn};
  ${horizontalPullFn};
  ${verticalPullFn};
  ${verticalPushFn};
  padding: 17px 16px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: white;
  background: white;
  border: 1px solid white;
  color: #a9aeb9;
  &:hover {
    background: white;
    box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2);
  }
`;

const Outline = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${horizontalPushFn};
  ${horizontalPullFn};
  ${verticalPullFn};
  ${verticalPushFn};
  // FONT
  font-weight: 500;
  color: #a9aeb9;
  // BORDER
  border: 1px solid #e3e5eb;
  border-radius: 8px;
  // FLEX
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 17px 16px;
  background: #f7f8fa;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  ${props =>
    props.isActive &&
    css`
      background: #e3e5eb;
    `}
  &:hover {
    background: #f7f8fa;
    box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2);
  }
`;

const White = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${horizontalPushFn};
  ${horizontalPullFn};
  ${verticalPullFn};
  ${verticalPushFn};
  padding: 13px;
  font-weight: 500;
  color: #a9aeb9;
  background-color: white;
  border: 1px solid #e3e5eb;
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
`;

const asBlue = css`
  ${props =>
    props.asBlue &&
    css`
      background: #4285f4;
      &:hover {
        background: #2966ca;
      }
    `}
`;

const asPurple = css`
  ${props =>
    props.asPurple &&
    css`
      background: #9862db;
      &:hover {
        background: #894cd6;
      }
    `}
`;

const Google = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${horizontalPushFn};
  ${horizontalPullFn};
  ${verticalPullFn};
  ${verticalPushFn};
  width: 100%;
  padding: 14px;
  background: #f7f8fa;
  border: 1px solid #e3e5eb;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  ${asPurple}
  ${asBlue}
`;

export { Primary, Secondary, Quaternary, Outline, White, Google };
