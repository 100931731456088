/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#fff' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="7" fill="#A9AEB9" />
      <path
        d="M10 7.19995V10.9333L11.8667 12.8"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
