/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M16.25 5.68625V5.75H3.75V5.68625C3.75 5.65922 3.75876 5.63291 3.77498 5.61128L5.13252 3.80003C5.15613 3.76854 5.19319 3.75 5.23254 3.75H14.7675C14.8068 3.75 14.8439 3.76854 14.8675 3.80003L16.225 5.61128C16.2412 5.63291 16.25 5.65921 16.25 5.68625ZM3.75 7.25H16.25V16.125C16.25 16.194 16.194 16.25 16.125 16.25H3.875C3.80596 16.25 3.75 16.194 3.75 16.125V7.25Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.75"
        y1="9.25"
        x2="12.25"
        y2="9.25"
        stroke="#A9AEB9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
