/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#EEEFF1" />
      <ellipse
        cx="7.95774"
        cy="11.8642"
        rx="2.73732"
        ry="1.22217"
        transform="rotate(-14.6869 7.95774 11.8642)"
        fill="#1D2A43"
      />
      <ellipse
        cx="20.5628"
        cy="12.0199"
        rx="2.73732"
        ry="1.22217"
        transform="rotate(15.2694 20.5628 12.0199)"
        fill="#1D2A43"
      />
      <circle
        cx="8.52282"
        cy="15.5741"
        r="0.698264"
        fill="#1D2A43"
        stroke="#1D2A43"
      />
      <circle
        cx="19.6951"
        cy="15.5741"
        r="0.698264"
        fill="#1D2A43"
        stroke="#1D2A43"
      />
      <path
        d="M14.109 14.6587V20.5516C14.109 21.0258 14.2974 21.4806 14.6327 21.8159L14.6626 21.8458C14.9788 22.162 15.4076 22.3396 15.8547 22.3396V22.3396H16.2038"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5529 25.6988C18.0658 25.8152 21.0916 25.0704 21.0916 21.1601"
        stroke="#1D2A43"
        strokeLinecap="round"
      />
      <path
        d="M13.6679 8.74845C14.2441 8.0801 14.9395 7.53177 15.676 7.04778C16.6092 6.43454 17.6081 5.78048 18.7105 5.53552"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4426 9.10543C18.6014 8.75498 18.7682 8.46273 19.0723 8.21791C19.7957 7.63558 20.6771 7.10522 21.5762 6.84944C21.6734 6.82181 21.8768 6.8335 21.9233 6.74036"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1018 9.41781C22.4613 8.97841 22.9103 8.79852 23.3959 8.52533"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51793 8.88229C10.713 7.29965 12.4115 6.17665 13.9803 5"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.23389 8.3022C5.86577 7.81073 6.43696 7.26334 7.11553 6.82961C7.91782 6.3168 8.76013 5.88746 9.62687 5.49585C9.81616 5.41032 10.0943 5.40892 10.1872 5.22314"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
