import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { parse } from 'date-fns';
import { format, addDays } from 'date-fns/fp';

import { Calendar } from '../Calendar';

import { Loader, Icon } from '../../../../common';

import { settingsSelector } from '../../../../state/settings/selectors';

import {
  addStartRange,
  addEndRange,
} from '../../../../state/settings/reducers';

const RangeContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`;

const RangeWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  background: #0d2241;
  position: relative;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.03);
`;

const RangeContent = styled.div`
  transition: max-height 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
`;

const RangeItem = styled.div`
  position: relative;
  margin-left: 16px;
  &:first-child {
    margin-left: 0;
  }
`;

const RangeRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 16px;
  right: 0px;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
`;

const PillItem = styled.span`
  margin: 16px 0px 8px 0px;
  padding-bottom: 8px;
  display: inline-flex;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 200px;
  transition: all 0.2s ease-in-out;
  line-height: 13px;
  font-size: 16px;
  color: white;
  margin-left: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 4px;
  background: transparent;
  &:first-child {
    margin-left: 0;
  }
`;

const ExpanderButton = styled.button`
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const ExpanderTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: black;
  text-transform: capitalize;
  padding: 8px 0px;
`;

const getSevenDays = addDays(7)(new Date());

const ExpanderHeader = styled.div`
  background: #ffffff;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  border-bottom: 1px solid transparent;
  margin-top: 0;
  ${props =>
    props.isActive &&
    css`
      border-bottom: 1px solid #e3e5eb;
    `}
`;

const DateRange = ({
  onClose = () => {},
  isActive = false,
  onToggle = () => {},
}) => {
  const { loading, range } = settingsSelector;

  const rangeSelector = useSelector(range);
  const { start, end } = rangeSelector;

  const isLoading = useSelector(loading);

  const parsedStartDate = parse(
    start ? start : '21-12-30',
    'yyyy-MM-dd',
    new Date(),
  );
  const parsedEndDate = parse(end ? end : '21-12-30', 'yyyy-MM-dd', new Date());

  const [startDate, setStartDate] = useState(parsedStartDate);
  const [endDate, setEndDate] = useState(parsedEndDate);

  console.log({ parsedStartDate, parsedEndDate });

  const contentRef = useRef(null);
  const [height, setHeightState] = useState(0);

  const dispatch = useDispatch();

  const onChangeStart = date => {
    setStartDate(date);
    dispatch(addStartRange(format('yy-MM-dd')(date)));
  };

  const onChangeEnd = date => {
    setEndDate(date);
    dispatch(addEndRange(format('yy-MM-dd')(date)));
  };

  const handleExpand = () => onToggle(!isActive);

  useEffect(() => {
    if (contentRef.current) {
      if (isActive) {
        setHeightState(contentRef.current.scrollHeight);
      } else {
        setHeightState(0);
      }
    }
  }, [contentRef.current, isActive]);

  return (
    <RangeContainer>
      {isLoading && <Loader />}
      <RangeWrapper>
        {/* {!isActive && (
          <ExpanderHeader isActive={isActive}>
            <ExpanderButton onClick={handleExpand}>
              <ExpanderTitle>{`Date Range`}</ExpanderTitle>
            </ExpanderButton>
          </ExpanderHeader>
        )} */}
        <RangeContent
          ref={contentRef}
          style={{ maxHeight: `${height}px` }}
          className="expander"
        >
          <RangeRow>
            <RangeItem>
              <PillItem>From:</PillItem>
              <Calendar
                className="start"
                onChange={onChangeStart}
                value={startDate}
                maxDate={getSevenDays}
              />
            </RangeItem>
            <RangeItem>
              <PillItem>To:</PillItem>
              <Calendar
                className="start"
                onChange={onChangeEnd}
                value={endDate}
                maxDate={getSevenDays}
              />
            </RangeItem>
            <CloseButton onClick={onClose}>
              <Icon name="CLOSE" size={20} />
            </CloseButton>
          </RangeRow>
        </RangeContent>
      </RangeWrapper>
    </RangeContainer>
  );
};

export { DateRange };
