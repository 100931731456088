import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { Context } from '../Provider';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';
import MenuBody from './MenuBody';
import MenuMobile from './MenuMobile';

import { media } from '../../../utils/styles';

const config = {
  minWidth: '70px',
  maxWidth: '282px',
};
// Container takes up relative space on document
const MenuContainer = styled.div`
  max-width: ${config.minWidth};
  width: 278px;
  /* overflow: hidden; */
  position: relative;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  display: none;
  ${media.sm`
    display: block;
  `}
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.06);
  ${props =>
    props.isExpanded &&
    css`
      max-width: ${config.maxWidth};
    `}
`;

// Overlays Container state fixed position
const MenuStyled = styled.div`
  position: fixed;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
  display: flex;
  left: 0;
  top: 0;
  max-width: ${config.minWidth};
  width: 276px;
  height: 100%;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.06);
  .isExpanded & {
    max-width: ${config.maxWidth};
  }
`;

const MenuContent = styled.div`
  transition: all 0.3s ease-in-out;
  padding: 24px 13px;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  .isExpanded & {
    padding: 24px;
  }
`;

const Menu = () => {
  const [state] = useContext(Context);
  const { menuExpanded: isExpanded } = state;

  return (
    <>
      <MenuMobile />
      <MenuContainer
        isExpanded={isExpanded}
        className={isExpanded ? 'isExpanded' : ''}
      >
        <MenuStyled>
          <MenuContent>
            <MenuHeader />
            <MenuBody />
            <MenuFooter />
          </MenuContent>
        </MenuStyled>
      </MenuContainer>
    </>
  );
};

export { Menu };
