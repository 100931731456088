/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.45455 2.75H14.5455C15.9258 2.75 17.1683 3.93907 17.25 5.47339V14.5455C17.25 16.0403 16.0403 17.25 14.5455 17.25H5.45455C3.95967 17.25 2.75 16.0403 2.75 14.5455V5.45455C2.75 3.95967 3.95967 2.75 5.45455 2.75Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M14.7994 8.38446C14.7708 8.2452 14.5798 8.1895 14.4461 8.26377C13.6821 8.72797 12.8894 9.28502 12.2018 9.86063C12.1827 9.35929 12.1254 8.84867 12.0204 8.34733C11.8771 7.70672 11.6002 7.34464 10.8934 7.1961C10.139 7.03827 9.36543 6.99185 8.60141 7.00113C7.8374 6.99185 7.06384 7.03827 6.30938 7.1961C5.60267 7.34464 5.33527 7.70672 5.19202 8.34733C5.04877 9.0065 4.99147 9.66567 5.00102 10.3341C4.99147 10.9933 5.04877 11.6618 5.19202 12.3209C5.33527 12.9708 5.60267 13.3329 6.30938 13.4814C7.06384 13.6393 7.7419 13.6671 8.60141 13.6764C9.46093 13.6857 10.139 13.6393 10.8934 13.4814C11.6002 13.3329 11.8676 12.9708 12.0108 12.3209C12.1159 11.8289 12.1732 11.3368 12.1923 10.8355C12.8799 11.4018 13.6725 11.9681 14.4461 12.423C14.5798 12.5066 14.7803 12.4416 14.7994 12.3024C15.0669 11.0119 15.0669 9.68424 14.7994 8.38446Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
