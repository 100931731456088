import React, { useState, useRef, useEffect } from 'react';
import PinInput from 'react-pin-input';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { media } from '../../utils/styles';
import { useKeypress } from '../../utils/hooks/useKeypress';

import { userSelector } from '../../state/user/selectors';
import { useSelector } from 'react-redux';

const FakePinContainer = styled.div`
  display: flex;
  input {
    padding: 0px;
    margin: 0px 2px;
    text-align: center;
    border: 1px solid #e3e5eb !important;
    background: transparent;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    outline: none;
    &:focus {
      outline: none;
      border: 1px solid #e3e5eb !important;
    }
  }
  button {
    min-height: 50px;
    margin-left: 4px;
  }
`;

const ViewPin = ({ value = '', onReset = () => {}, ...props }) => {
  const valueLength = String(value).split('');
  return (
    <FakePinContainer {...props}>
      {valueLength.map((current, index) => (
        <input key={`${current}-${index}`} value={current} disabled />
      ))}
      <Button variant="OUTLINE" onClick={onReset}>
        Reset
      </Button>
    </FakePinContainer>
  );
};

const Controls = styled.div`
  margin-top: 16px;
  display: flex;

  ${media.sm`
    margin: 0;
  `}
`;

const PinContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  button {
    min-height: 50px;
    margin-left: 4px;
  }
  input {
    border: 1px solid #e3e5eb !important;
    border-radius: 8px;
    box-shadow: none;
    border-style: solid !important;
    outline: none;
    appearance: none;
  }
  .controls {
    flex-wrap: wrap;
    ${media.sm`
    flex-wrap: wrap;
    `}
  }
`;

const Pin = ({ callback = () => {}, value, style = {} }) => {
  const [localValue, setLocalValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);

  const { loading } = userSelector;
  const isLoading = useSelector(loading);
  const pin = useRef(null);

  const onChange = value => {
    setLocalValue(value);
  };

  useKeypress('Escape', () => {
    setLocalValue(value);
    setIsEdit(false);
  });

  const onClear = () => {
    setLocalValue('');
    pin.current.clear();
    setIsEdit(false);
  };

  const onSave = () => {
    callback(localValue);
    setIsEdit(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    if (localValue && localValue.length >= 4) {
      onSave();
    }
  };

  const renderPin = () => (
    <PinContainer style={style} onSubmit={onSubmit}>
      <PinInput
        length={4}
        focus
        secret
        ref={pin}
        type="numeric"
        onChange={onChange}
      />
      {/* {localValue && localValue.length >= 1 && (
        <Button variant="OUTLINE" onClick={onClear}>
          <span style={{ marginRight: '8px' }}>Clear</span>{' '}
          <Icon name="CLEAR" size={20} />
        </Button>
      )} */}
      {localValue && localValue.length >= 4 && (
        <Controls className="controls">
          <Button
            variant="OUTLINE"
            style={{ marginLeft: '4px' }}
            onClick={onSave}
          >
            <span style={{ marginRight: '8px' }}>Save</span>{' '}
            <Icon name="SAVE" size={20} />
          </Button>
        </Controls>
      )}
    </PinContainer>
  );

  return (
    <>
      {isEdit ? (
        <div>{renderPin()}</div>
      ) : (
        <div>
          {!isLoading && (
            <ViewPin onReset={() => setIsEdit(true)} value={value} />
          )}
        </div>
      )}
    </>
  );
};

export { Pin };
