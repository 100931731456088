/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.65381 16.2339L4.38456 15.5031"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5029 4.38452L16.2337 3.65377"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33057 11.5571L11.5001 8.38751"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.58824 12.1397L3.01179 12.7162C2.62127 13.1067 2.62127 13.7399 3.0118 14.1304L5.86964 16.9882C6.26016 17.3788 6.89335 17.3787 7.28387 16.9882C7.42674 16.8453 7.56347 16.7086 7.70019 16.5719"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8785 3.84948L12.7161 3.01183C13.1067 2.62131 13.7398 2.62131 14.1304 3.01183L16.9882 5.86967C17.3787 6.2602 17.3787 6.89336 16.9882 7.28389L16.1595 8.11264"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.42822"
        y="10.1518"
        width="3.17979"
        height="10.4933"
        rx="1"
        transform="rotate(-45 2.42822 10.1518)"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="17.5718"
        y="9.84827"
        width="3.17979"
        height="10.4933"
        rx="1"
        transform="rotate(135 17.5718 9.84827)"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
