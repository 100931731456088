import React from 'react';
import { Icon } from '../Icon';
import { length } from 'ramda';
import {
  Container,
  ErrorWrapper,
  ErrorContent,
  ErrorIcon,
  ErrorClose,
} from './ErrorList.styled';
import PropTypes from 'prop-types';

const Message = ({
  onClose = () => {},
  name = '',
  hasClose = false,
  message = 'There was an error',
}) => {
  return (
    <ErrorWrapper>
      <ErrorContent className={name} hasClose={hasClose}>
        <ErrorIcon>
          <Icon name="BOLT" fill="#F72E44" size={20} />
        </ErrorIcon>
        {message && <p>{message}</p>}
        {hasClose && (
          <ErrorClose onClick={onClose}>
            <Icon
              name="CLOSE_SMALL"
              size={20}
              stroke="#f72e44"
              viewBox="-1 -1 20 20"
            />
          </ErrorClose>
        )}
      </ErrorContent>
    </ErrorWrapper>
  );
};

const ErrorList = ({
  needles = [],
  haystack = [],
  callback = () => {},
  style = {},
  ...rest
}) => {
  const filteredErrors = haystack
    .filter(match => needles.includes(match.name))
    .map(error => error);

  if (!filteredErrors || !length(filteredErrors)) return null;

  return filteredErrors.map((item, index) => (
    <Container key={`error-${index}`} style={style}>
      <Message
        key={index}
        message={item.message}
        name={item.name}
        onClose={() => callback(item.name)}
        {...rest}
      />
    </Container>
  ));
};

Message.propTypes = {
  /** Array of items passed in which can be rendered */
  needles: PropTypes.array,
  /** Array of items to be filtered by */
  haystack: PropTypes.array,
  /** Function to invoke when the user clicks on the close button with a customised outcome.*/
  callback: PropTypes.func,
};

export { ErrorList };
