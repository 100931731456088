import React from 'react';
import PropTypes from 'prop-types';
import { keys, includes, toUpper, length, prop } from 'ramda';
import { Icon } from '../Icon';

import {
  Primary,
  Secondary,
  Quaternary,
  Outline,
  White,
  Google,
} from './Button.styled';

const Button = ({ variant, style, config, children, ...props }) => {
  const matchedLayoutProps = prop('layout')(config) || {};
  const matchedTextProps = prop('text')(config) || {};
  const match = {
    PRIMARY: (
      <Primary style={{ ...style, ...matchedLayoutProps }} {...props}>
        {length(keys(matchedTextProps)) > 0 ? (
          <span style={{ ...matchedTextProps }}>{children}</span>
        ) : (
          children
        )}
      </Primary>
    ),
    SECONDARY: (
      <Secondary
        style={{ ...style, ...matchedLayoutProps, ...matchedTextProps }}
        {...props}
      >
        {children}
      </Secondary>
    ),
    QUATERNARY: (
      <Quaternary
        style={{ ...style, ...matchedLayoutProps, ...matchedTextProps }}
        {...props}
      >
        {children}
      </Quaternary>
    ),
    OUTLINE: (
      <Outline
        style={{ ...style, ...matchedLayoutProps, ...matchedTextProps }}
        {...props}
      >
        {children}
      </Outline>
    ),
    WHITE: (
      <White
        style={{ ...style, ...matchedLayoutProps, ...matchedTextProps }}
        {...props}
      >
        {children}
      </White>
    ),
    GOOGLE: (
      <Google
        asBlue
        style={{ ...style, ...matchedLayoutProps, ...matchedTextProps }}
        {...props}
      >
        <Icon name="GOOGLE" fill="white" size={20} />
        <span style={{ marginLeft: '16px', color: 'white', fontWeight: 500 }}>
          Sign up with Google
        </span>
      </Google>
    ),
  };

  if (!includes(toUpper(variant), keys(match))) {
    return (
      <Primary style={{ ...style, ...matchedLayoutProps }} {...props}>
        {children}
      </Primary>
    );
  }

  return match[toUpper(variant)];
};

Button.defaultProps = {
  variant: 'PRIMARY',
  style: {},
  config: {},
  children: <></>,
};

Button.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  children: PropTypes.node,
};

export { Button };
