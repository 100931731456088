/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9', size } = props;

  const factor = 0.345459424235573;

  const animator = size / 2;

  const config = {
    max: factor * size,
    min: factor * size,
    radius: size - size * 0.7,
  };

  //   const value = 1.219512195121951;

  return (
    <>
      <circle
        cx={animator}
        cy={animator}
        fill="none"
        stroke="#E3E5EB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        r={config.radius}
      ></circle>
      <circle
        cx={animator}
        cy={animator}
        fill="none"
        stroke="#FF5A45"
        strokeWidth="1.5"
        r={config.radius}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray={`9 20`}
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values={`0 ${animator} ${animator}; 360 ${animator} ${animator}`}
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </>
  );
};

export default IconPath;
