import React from 'react';
import PropTypes from 'prop-types';
import { compose, prop, findIndex, __, propEq, toUpper } from 'ramda';

import { Icon } from '../Icon';

import {
  Container,
  StepItem,
  StepGroup,
  StepText,
  StepCircle,
  VisuallyHidden,
  StepDash,
} from './Steps.styled';

const getIndex = steps => lookup =>
  compose(findIndex(__, steps), propEq('id'))(lookup);

const Steps = ({ steps, currentStep, mt, mb, callback, config, ...props }) => {
  const callbackEnabled = prop('callback')(config);
  const width = prop('width')(config);
  const align = prop('align')(config);
  const bgColor = prop('bgColor')(config);
  const stepLength = (steps && steps.length) || 0;

  const onCallback = value => {
    const { id } = value;
    if (!callbackEnabled || getIndex(steps)(id) >= getIndex(steps)(currentStep))
      return;
    callback(value);
  };

  const renderSteps = () => {
    if (!steps || steps.length <= 0) return <></>;
    return steps.map((item, index) => {
      const iteratorAdjustment = index + 1;
      const isActive = getIndex(steps)(currentStep);
      const isCompleted = index < isActive;
      const isCenter = align && toUpper(align) === 'CENTER';
      const isCurrentStep = isActive + 1 === iteratorAdjustment;

      const srText = `${
        isCompleted ? 'Completed ' : isCurrentStep ? 'Current ' : ''
      }Step ${iteratorAdjustment}`;

      return (
        <StepItem
          center={isCenter}
          className="stepperItem"
          onClick={() => onCallback(item)}
          key={`${item.id}`}
        >
          <StepCircle isCompleted={isCompleted} isActive={isActive === index}>
            {!isCompleted && (
              <StepText className="stepText" aria-hidden="true">
                {prop('label')(item) || iteratorAdjustment}
              </StepText>
            )}
            {isCompleted && (
              <Icon name="TICK" stroke="white" size={20} viewBox="0 0 20 20" />
            )}
            <VisuallyHidden>{srText}</VisuallyHidden>
          </StepCircle>
          {stepLength > 1 && index < stepLength - 1 ? (
            <StepDash className="stepDash" count={stepLength} />
          ) : (
            <></>
          )}
        </StepItem>
      );
    });
  };

  return (
    <Container
      mt={mt}
      mb={mb}
      bgColor={bgColor && bgColor}
      width={width}
      {...props}
    >
      <StepGroup>{renderSteps()}</StepGroup>
      <VisuallyHidden aria-live="polite" aria-atomic="true">
        Step {getIndex(steps)(currentStep) + 1} of {steps.length}
      </VisuallyHidden>
    </Container>
  );
};

Steps.defaultProps = {
  callback: () => {},
  config: {},
  mt: 0,
  mb: 0,
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  currentStep: PropTypes.string.isRequired,
  callback: PropTypes.func,
  mt: PropTypes.number,
  mb: PropTypes.number,
  config: PropTypes.shape({
    width: PropTypes.string,
    bgColor: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'LEFT', 'CENTER']),
    callback: PropTypes.func,
  }),
};

export { Steps };
