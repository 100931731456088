/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M9.02222 14.5333V6.71111C9.02222 5.87655 9.69877 5.2 10.5333 5.2V5.2C11.3679 5.2 12.0444 5.87655 12.0444 6.71111V14.9778C12.0444 16.6469 10.6913 18 9.02222 18V18C7.35309 18 6 16.6469 6 14.9778V6.53333C6 4.02964 8.02964 2 10.5333 2V2C13.037 2 15.0667 4.02964 15.0667 6.53333V14.5333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
