import React, { useState } from 'react';
import { Menu, Provider, Main } from '../../Layout';
import { Controls } from './Controls';
import { prop } from 'ramda';
import styled from 'styled-components';

const Container = styled.div``;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
`;

const Heading = styled.h2`
  font-size: 26px;
`;

const Client = ({ history }) => {
  const [activeTab, setActiveTab] = useState('details');

  const name = prop('name')(history.location.state);
  const content = prop('content')(history.location.state);
  return (
    <Provider>
      <Menu />
      <Main>
        <Container>
          <Header>{name && <h2>{name}</h2>}</Header>
          <Controls active={activeTab} callback={setActiveTab} />
          {activeTab === 'details' && (
            <div>
              <Heading>Details</Heading>
              {content && <p>{content}</p>}
            </div>
          )}
        </Container>
      </Main>
    </Provider>
  );
};

export { Client };
