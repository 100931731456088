// import * as firebase from 'firebase/app';
// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { config } from './config';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

const db = firebase.firestore();
const storage = firebase.storage();

export { db, storage, firebase };
