/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="1.75"
        y="5.75"
        width="16.5"
        height="8.5"
        rx="1.25"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <line
        x1="5.25"
        y1="11.25"
        x2="5.25"
        y2="8.75"
        stroke="#A9AEB9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
