/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#1D2A43" />
      <path
        d="M10 14.5L10 6.5"
        stroke="#1D2A43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.5L10 6.49988L13.5 9.49988"
        stroke="#1D2A43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
