import React, { useState, useEffect } from 'react';

import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { prop } from 'ramda';

import { media } from '../../../utils/styles';
import { uuid } from '../../../utils/uuid';
import { format, addDays } from 'date-fns/fp';
import { Loader, Icon, Button, Text, Input, Heading } from '../../../common';
// import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouter } from '../../../utils/hooks/useRouter';
import { fetchClients } from '../../../state/client/reducers';
import { clientSelector } from '../../../state/client/selectors';
import { fetchNotifications } from '../../../state/client/reducers';

const Group = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
`;

const Container = styled.div`
  padding: 0px;
`;

const ItemContainer = styled.div`
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.03);
  cursor: pointer;
`;

const ItemHeader = styled.div`
  background: #ffffff;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  transition: all 0.4s ease-in-out;
  border-bottom: 1px solid transparent;
  margin-top: 0;
`;

const ItemTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: black;
  text-transform: capitalize;
  padding: 8px 0px;
`;

const ItemId = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: black;
  margin-left: auto;
  text-transform: capitalize;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  color: black;
  span {
    color: #778092;
    padding-right: 4px;
  }
`;

const ItemImage = styled.div`
  width: 0px;
  min-height: 32px;
  height: 32px;
  display: inline-block;
  margin: 8px 0px;
  box-sizing: border-box;
`;

const ItemNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Item = ({ notifications = [], ...item }) => {
  const history = useHistory();
  const name = prop('name')(item);
  const uid = prop('uid')(item);
  const hasNotification = notifications && notifications.length > 0;

  console.log('notifications -- Item ::', notifications);

  const onNavigate = () =>
    history.push({
      pathname: `/clients/${item.id}`,
      state: item,
    });

  return (
    <ItemContainer onClick={onNavigate}>
      <ItemHeader>
        <ItemImage />
        {name && <ItemTitle>{name}</ItemTitle>}
        {uid && (
          <ItemId>
            <span>ID:</span>
            {uid}
          </ItemId>
        )}
        {hasNotification && (
          <ItemNotification>
            <Icon name="NOTIFICATION" size={20} />
          </ItemNotification>
        )}
      </ItemHeader>
    </ItemContainer>
  );
};

const List = () => {
  const dispatch = useDispatch();

  const { loading, clients, notifications } = clientSelector;

  const isLoading = useSelector(loading);
  const clientList = useSelector(clients);
  const notificationList = useSelector(notifications);

  const matchedNotifcations = (assignedTo = []) => {
    const found = notificationList.filter(t => {
      return t.assignedTo, t.assignedTo.includes(assignedTo);
    });
    return found;
  };

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchNotifications());
  }, []);

  const renderItems = () => {
    if (!clientList || clientList.length <= 0) return null;
    return (
      <React.Fragment>
        <Group>
          {clientList.map(item => (
            <React.Fragment key={item.id}>
              <Item
                {...item}
                notifications={matchedNotifcations(item.assignedTo)}
              />
            </React.Fragment>
          ))}
        </Group>
      </React.Fragment>
    );
  };

  return (
    <Container>
      <Heading>Clients</Heading>
      {renderItems()}
      {isLoading && <Loader />}
    </Container>
  );
};

export { List };
