import React from 'react';
import { ItemContainer, ItemLabel, ItemDate } from './DayItem.styled';

const DayItem = ({ label, date, onClick, isActive, isSelected, ...props }) => (
  <ItemContainer
    isActive={isActive}
    isSelected={isSelected}
    onClick={onClick}
    {...props}
  >
    {label && <ItemLabel>{label}</ItemLabel>}
    {date && <ItemDate>{date}</ItemDate>}
  </ItemContainer>
);

export { DayItem };
