import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from './theme';
import { GlobalTheme } from './GlobalTheme';

const ThemeProvider = ({ children }) => (
  <>
    <GlobalTheme />
    <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
  </>
);

export { ThemeProvider };
