/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#FF5A45' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4.24201 16.5399L3.84519 16.9367M16.9367 3.84481L16.5399 4.24163M7.15663 17.3335L7.3983 17.0919C7.78883 16.7013 7.78883 16.0682 7.3983 15.6776L4.94878 13.2281C4.55826 12.8376 3.92509 12.8376 3.53457 13.2281L3.29289 13.4698C2.90237 13.8603 2.90237 14.4935 3.29289 14.884L5.74242 17.3335C6.13294 17.724 6.76611 17.724 7.15663 17.3335ZM11.4175 7.55338L13.0734 9.20927L9.20966 13.073L7.55377 11.4171L11.4175 7.55338ZM13.4694 3.29289L13.2278 3.53457C12.8372 3.92509 12.8372 4.55826 13.2278 4.94878L15.6773 7.3983C16.0678 7.78883 16.701 7.78883 17.0915 7.3983L17.3332 7.15663C17.7237 6.76611 17.7237 6.13294 17.3332 5.74242L14.8837 3.29289C14.4931 2.90237 13.86 2.90237 13.4694 3.29289ZM10.822 16.98L10.9085 16.8935C11.4943 16.3077 11.4943 15.3579 10.9085 14.7721L5.8543 9.71795C5.26851 9.13216 4.31876 9.13216 3.73297 9.71795L3.64645 9.80448C3.06066 10.3903 3.06066 11.34 3.64645 11.9258L8.70064 16.98C9.28642 17.5658 10.2362 17.5658 10.822 16.98ZM9.80423 3.64643L9.71771 3.73296C9.13192 4.31875 9.13192 5.26849 9.7177 5.85428L14.7719 10.9085C15.3577 11.4943 16.3074 11.4943 16.8932 10.9085L16.9797 10.8219C17.5655 10.2362 17.5655 9.28641 16.9797 8.70062L11.9256 3.64643C11.3398 3.06064 10.39 3.06064 9.80423 3.64643Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
