import React, { useContext } from 'react';

import styled, { css } from 'styled-components';
import { Icon } from '../../../common';

import { Context } from '../Provider';

const transitionEase = '.3s all ease-in-out';

const config = {
  maxWidth: `392px`,
  maxWidthInner: `380px`,
};

const BladeContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 10px;
  background: #f7f8fa;
  position: relative;
  transition: ${transitionEase};
  height: calc(100vh - 12px);
  ${props =>
    props.isExpanded &&
    css`
      max-width: ${config.maxWidth};
    `};
`;

const BladeHidden = styled.div`
  position: relative;
  max-width: ${config.maxWidth};
  /* min-width: 200px; */
  width: 100%;
  height: 100%;
  transition: ${transitionEase};
  ${props =>
    props.bladeExpanded &&
    css`
      max-width: ${config.maxWidth};
    `};
`;

const BladeStyled = styled.div`
  max-width: ${config.maxWidthInner};
  min-width: 200px;
  width: 100%;
  border-radius: 8px;
  position: fixed;
  top: 12px;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.06);
  border-radius: 8px;
  background: white;
  transition: height 0.3s ease-in-out;
  height: calc(100vh - 24px);
  overflow-y: hidden;
  ${props =>
    props.height &&
    css`
      height: ${props.height - 50}px;
    `};
`;

const BladeKnob = styled.div`
  width: 6px;
  height: 30px;
  background: #a9aeb9;
  border-radius: 21px;
  margin-right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  transition: all.2s ease-in-out;
`;

const BladeClickable = styled.div`
  width: 24px;
  transform: translate(-14px, 0%);
  position: fixed;
  height: calc(100vh - 12px);
  ${props =>
    props.isOnTop &&
    css`
      z-index: 10;
    `};
  cursor: pointer;
  transition: ${transitionEase};
  &:hover {
    background: transparent;
    .knob {
      transform: translate(-4px, -50%);
    }
  }
`;

const BladeHeader = styled.div`
  padding: 24px 16px 12px 16px;
`;

const BladeControls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const BladeButton = styled.button`
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e9ebf1;
  border-radius: 4px;
`;

const BladeTitle = styled.div`
  font-weight: 500;
  line-height: 16px;
  padding-top: 4px;
  font-size: 16px;
  padding-bottom: 4px;
`;

const Blade = ({ title = '', children }) => {
  const [state, dispatch] = useContext(Context);
  const { bladeExpanded } = state;

  const onToggle = () => {
    if (bladeExpanded) {
      dispatch({ type: 'CLOSE_BLADE' });
    } else {
      dispatch({ type: 'OPEN_BLADE' });
    }
  };

  return (
    <BladeContainer isExpanded={bladeExpanded}>
      <BladeClickable isOnTop={!bladeExpanded} onClick={onToggle}>
        <BladeKnob className="knob" />
      </BladeClickable>
      <BladeHidden bladeExpanded={bladeExpanded}>
        <BladeStyled>
          <BladeHeader>
            <BladeControls>
              <BladeTitle>{title || `Library`}</BladeTitle>
              <BladeButton onClick={onToggle}>
                <Icon name="ARROWRIGHT" size={20} />
              </BladeButton>
            </BladeControls>
          </BladeHeader>
          {children}
        </BladeStyled>
      </BladeHidden>
    </BladeContainer>
  );
};

export { Blade };
