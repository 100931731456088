/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#FF5A45' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="1" y="1" width="8.18181" height="8.18181" rx="2" fill={fill} />
      <rect
        x="1"
        y="10.8182"
        width="8.18181"
        height="8.18181"
        rx="2"
        fill={fill}
      />
      <rect
        x="10.8183"
        y="1"
        width="8.18181"
        height="8.18181"
        rx="2"
        fill={fill}
      />
      <rect
        x="10.8183"
        y="10.8182"
        width="8.18181"
        height="8.18181"
        rx="2"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
