import { createSelector } from '@reduxjs/toolkit';

const selectClientState = state => state.client;

const loading = createSelector(selectClientState, slice => slice.loading);
const clients = createSelector(selectClientState, slice => slice.clients);
const notifications = createSelector(
  selectClientState,
  slice => slice.notifications,
);
const error = createSelector(selectClientState, slice => slice.error);

export const clientSelector = {
  loading,
  clients,
  notifications,
  error,
};
