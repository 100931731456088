/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="1.75"
        y="1.75"
        width="16.5"
        height="16.5"
        rx="1.25"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M10 13.5L10 5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3536 16.1464L13.1464 13.3536C13.4614 13.0386 13.2383 12.5 12.7929 12.5L7.20711 12.5C6.76165 12.5 6.53857 13.0386 6.85355 13.3536L9.64645 16.1464C9.84171 16.3417 10.1583 16.3417 10.3536 16.1464Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
