import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db, storage } from '../../utils/services';

const initialState = {
  loading: false,
  clients: [],
  notifications: [],
  error: '',
};

export const getClientById = async uid => {
  const snapshot = await db.collection('clients').doc(uid).get();
  return snapshot;
};

export const fetchNotifications = createAsyncThunk(
  'client/fetchNotifications',
  async () => {
    const snapshot = await db.collection('notifications').get();
    const data = snapshot.docs.map(doc => doc.data());
    if (data) {
      return data;
    } else {
      console.log('error');
    }
  },
);

export const fetchClients = createAsyncThunk(
  'client/fetchClients',
  async (payload, { rejectWithValue }) => {
    const snapshot = await db.collection('clients').get();
    const data = snapshot.docs.map(doc => doc.data());
    if (data) {
      return data;
    } else {
      console.log('error');
      rejectWithValue('No clients found');
    }
  },
);

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClients.pending]: state => {
      state.loading = true;
    },
    [fetchClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.clients = payload;
      state.error = '';
    },
    [fetchClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [fetchNotifications.pending]: state => {
      state.loading = true;
    },
    [fetchNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload;
      state.error = '';
    },
    [fetchNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default clientSlice.reducer;
