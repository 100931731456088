import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firebase } from '../../utils/services';
import { getFromLS, saveToLS } from '../../utils/storage';

const initialState = {
  loading: false,
  persisted: false,
  authenticated: false,
  user: {},
};

export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  await firebase
    .auth()
    .signOut()
    .then(() => {
      console.log('signed out');
    })
    .catch(error => {
      console.log('signed out error!!!');
    });
});

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }) =>
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        if (res) {
          saveToLS({ key: 'authenticated', value: true });
          return res.user;
        }
      }),
);

export const persistUser = createAsyncThunk(
  'auth/persistUser',
  async () => await firebase.auth().onAuthStateChanged(user => user),
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [loginUser.pending]: state => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.authenticated = true;
      state.user = payload;
    },
    [loginUser.rejected]: state => {
      state.loading = false;
    },
    [persistUser.pending]: state => {
      state.loading = true;
    },
    [persistUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.persisted = true;
      state.user = payload;
    },
    [persistUser.rejected]: state => {
      state.loading = false;
      state.persisted = false;
    },
    [logoutUser.pending]: state => {
      state.loading = true;
    },
    [logoutUser.fulfilled]: state => {
      state.loading = false;
      state.persisted = false;
      state.authenticated = false;
      state.user = {};
    },
    [logoutUser.rejected]: state => {
      state.loading = false;
      state.persisted = false;
    },
  },
});

export default authSlice.reducer;
