import React, { useState, useRef } from 'react';

import styled from 'styled-components';

const ExpanderContainer = styled.div`
  &.accordion__section {
    display: flex;
    flex-direction: column;
  }

  .accordion {
    background: #ffffff;
    cursor: pointer;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      /* background-color: #e0e0e0; */
    }
    &.active {
      /* background-color: #e0e0e0; */
    }
  }

  /* Style the accordion content title */
  .accordion__title {
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    margin: 0;
    text-transform: capitalize;
    padding: 8px 0px;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: auto;
    transition: max-height 0.2s ease-in-out;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-weight: 400;
    font-size: 14px;
  }
`;

function Chevron(props) {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill={props.fill}
        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
      />
    </svg>
  );
}

const Expander = ({ children, title, ...props }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const content = useRef(null);

  const toggleExpander = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate',
    );
  };

  return (
    <ExpanderContainer className="accordion__section">
      <button className={`accordion ${setActive}`} onClick={toggleExpander}>
        <p className="accordion__title">{title}</p>
        <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div className="accordion__text" />
        {children}
      </div>
    </ExpanderContainer>
  );
};

export { Expander };
