import styled, { css } from 'styled-components';

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${props =>
    props.verticalPush &&
    css`
      margin-top: ${props.verticalPush};
    `}
  ${props =>
    props.verticalPull &&
    css`
      margin-bottom: ${props.verticalPull};
    `}
`;
const ErrorWrapper = styled.li`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 12px 10px 16px;
  min-height: 48px;
  background: #ffe4e6;
  border-radius: 6px;
  box-sizing: border-box;
`;

const ErrorContent = styled.div`
  line-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${props =>
    props.hasClose &&
    css`
      p {
        padding-right: 8px;
      }
    `}
  p {
    font-size: 16px;
    color: #f72e44;
    line-height: 21px;
    margin: 0;
    padding: 0;
  }
`;

const ErrorClose = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #ffd9dc;
  border-radius: 6px;
  margin-left: auto;
  padding: 6px;
  &:focus {
    outline: none;
  }
`;

const ErrorIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, ErrorWrapper, ErrorContent, ErrorClose, ErrorIcon };
