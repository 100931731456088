/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = '#F72E44' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09882 9.55112C1.96706 9.78169 1.96706 10.0648 2.09882 10.2953L5.62638 16.4686C5.75991 16.7023 6.00842 16.8465 6.27756 16.8465H13.3327C13.6018 16.8465 13.8503 16.7023 13.9839 16.4686L17.5114 10.2953C17.6432 10.0648 17.6432 9.78169 17.5114 9.55112L13.9839 3.37789C13.8503 3.14421 13.6018 3 13.3327 3H6.27756C6.00842 3 5.75991 3.14421 5.62638 3.3779L2.09882 9.55112ZM12.8974 15.3465H6.71281L3.61381 9.92322L6.7128 4.5L12.8974 4.5L15.9964 9.92322L12.8974 15.3465ZM8.35029 9.92323C8.35029 9.1198 9.00159 8.4685 9.80502 8.4685C10.6084 8.4685 11.2597 9.1198 11.2597 9.92323C11.2597 10.7267 10.6084 11.378 9.80502 11.378C9.00159 11.378 8.35029 10.7267 8.35029 9.92323ZM9.80502 6.9685C8.17317 6.9685 6.85029 8.29138 6.85029 9.92323C6.85029 11.5551 8.17317 12.878 9.80502 12.878C11.4369 12.878 12.7597 11.5551 12.7597 9.92323C12.7597 8.29138 11.4369 6.9685 9.80502 6.9685Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
