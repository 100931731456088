import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db, storage } from '../../utils/services';
// import { saveToLS } from '../../utils/storage';
import { prop } from 'ramda';

// saveToLS({ key: uid, value: pin });

const initialState = {
  loading: false,
  list: [],
  error: '',
};

// return {
//   'xyz-123': {
//     id: 'xyz-123',
//     name: 'Jenny Park',
//     createdOn: '27 December 2021 at 00:00:00 UTC+11',
//     jobs: {
//       '1xksls923': {
//         id: '1xksls923',
//         name: 'LTSC', // accronymn
//         createdOn: '27 December 2021 at 00:00:00 UTC+11',
//         details: `This job is for the location of the water field under lamp 64`,
//         address: {
//           street: `234 Garlend Lane`,
//           city: 'Young',
//           postcode: '1234',
//           state: 'NSW',
//         },
//       },
//       '1xksls925': {
//         id: '1xksls925',
//         name: 'LTSC', // accronymn
//         createdOn: '27 December 2021 at 00:00:00 UTC+11',
//         details: `This job is for the doing nothing`,
//         address: {
//           street: `2 Heath Street`,
//           city: 'Sydney',
//           postcode: '1234',
//           state: 'NSW',
//         },
//       },
//     },
//     actions: [
//       {
//         id: 'hlsl92',
//         name: 'hlsl92',
//         type: 'irregular',
//         content: 'This job should be completed at 8:00am',
//         tools: ['hammer', 'shovel', 'ladder'],
//       },
//       {
//         id: 'hl18l92',
//         name: 'hlsl002',
//         type: 'regular',
//         createdOn: '27 December 2021 at 00:00:00 UTC+11',
//         content: 'This job should normal',
//         tools: ['fuel'],
//       },
//     ],
//     notifications: [
//       {
//         id: '28w8sl',
//         createdOn: '27 December 2021 at 00:00:00 UTC+11',
//         type: 'irregular',
//         closed: 'yes',
//         createdBy: 'davesmith', // employee id / admin id
//         content: `Some date prevelant to the task requirements`,
//       },
//       {
//         id: '28w8s33',
//         createdOn: '27 December 2021 at 00:00:00 UTC+11',
//         type: 'irregular',
//         closed: 'no',
//         createdBy: 'douglynch', // employee id / admin id
//         content: `Some date prevelant to the task requirements`,
//       },
//     ],
//   },
// };

export const addUserColor = createAsyncThunk(
  'user/addUserColor',
  async ({ uid, color }, { dispatch, rejectWithValue }) => {
    let data = null;
    await getUserById(uid)
      .then(foundUser => {
        if (foundUser.exists) {
          data = foundUser.data();
        } else {
          rejectWithValue('user-not-found');
        }
      })
      .catch(() => {
        rejectWithValue('user-not-found');
      });

    if (data) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          ...(data || {}),
          color,
        });
      dispatch(fetchUsers());
    }
  },
);

export const getUserById = async uid => {
  const snapshot = await db.collection('users').doc(uid).get();
  return snapshot;
};

export const addPinById = createAsyncThunk(
  'user/addPinById',
  async ({ uid, pin }, { dispatch, rejectWithValue }) => {
    let data = null;
    await getUserById(uid)
      .then(foundUser => {
        if (foundUser.exists) {
          data = foundUser.data();
        } else {
          rejectWithValue('user-not-found');
        }
      })
      .catch(() => {
        rejectWithValue('user-not-found');
      });

    if (data) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          ...(data || {}),
          pin,
        });
      dispatch(fetchUsers());
    }
  },
);

export const addUserImage = createAsyncThunk(
  'user/addUserImage',
  async ({ image, uid }, { dispatch }) => {
    await storage.ref(`/images/${uid}`).put(image);

    let userImage = null;

    await storage
      .ref()
      .child(`/images/${uid}`)
      .getDownloadURL()
      .then(function (url) {
        console.log(url);
        userImage = url;
      })
      .catch(function (err) {
        console.log('There was an err', err);
      });

    if (userImage) {
      await db.collection('users').doc(uid).update({
        image: userImage,
      });
      dispatch(fetchUsers());
    }
  },
);

export const removeEntry = createAsyncThunk(
  'user/removeEntry',
  async ({ id, uid }, { dispatch, rejectWithValue }) => {
    let data = null;
    console.log('philip:', uid);

    await getUserById(uid)
      .then(foundUser => {
        if (foundUser.exists) {
          data = foundUser.data();
        } else {
          rejectWithValue('user-not-found');
        }
      })
      .catch(() => {
        rejectWithValue('user-not-found >> catch');
      });
    if (data) {
      const entries = prop('entries')(data) || [];
      const filteredEntries = entries.filter(item => item.id !== id);
      await db
        .collection('users')
        .doc(uid)
        .update({
          ...data,
          entries: filteredEntries,
        });
      dispatch(fetchUsers());
    }
  },
);

export const addEntry = createAsyncThunk(
  'user/addEntry',
  async ({ uid, item = {} }, { dispatch, rejectWithValue }) => {
    let data = null;
    await getUserById(uid)
      .then(foundUser => {
        if (foundUser.exists) {
          data = foundUser.data();
        }
      })
      .catch(err => {
        rejectWithValue('user-not-found');
      });
    if (!data) {
      return;
    }
    if (data) {
      const entries = prop('entries')(data) || [];
      await db
        .collection('users')
        .doc(uid)
        .update({
          ...data,
          entries: [...entries, item],
        });
      dispatch(fetchUsers());
    }
  },
);

export const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (payload = '', { rejectWithValue }) => {
    const snapshot = await db.collection('users').get();
    const data = snapshot.docs.map(doc => doc.data());
    if (data) {
      return data;
    } else {
      console.log('error');
      rejectWithValue('No users found');
    }
  },
);

export const removeUser = createAsyncThunk(
  'user/removeUser',
  async (uid, { dispatch, rejectWithValue }) => {
    await db
      .collection('users')
      .doc(uid)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch(error => {
        console.error('Error removing document: ', error);
        rejectWithValue(`Error removing document`);
      });
    dispatch(fetchUsers());
  },
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async (data, { dispatch }) => {
    const { uid, firstname, lastname, pin } = data;
    await db.collection('users').doc(uid).set({
      uid: uid,
      firstname: firstname,
      lastname: lastname,
      pin: pin,
    });
    dispatch(fetchUsers());
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [addUser.pending]: state => {
      state.loading = true;
    },
    [addUser.fulfilled]: state => {
      state.loading = false;
    },
    [addUser.rejected]: state => {
      state.loading = false;
    },
    [fetchUsers.pending]: state => {
      state.loading = true;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload;
      state.error = '';
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [removeUser.pending]: state => {
      state.loading = true;
    },
    [removeUser.fulfilled]: state => {
      state.loading = false;
      state.error = '';
    },
    [removeUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addEntry.pending]: state => {
      state.loading = true;
    },
    [addEntry.fulfilled]: state => {
      state.loading = false;
      state.error = '';
    },
    [addEntry.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [removeEntry.pending]: state => {
      state.loading = true;
    },
    [removeEntry.fulfilled]: state => {
      state.loading = false;
      state.error = '';
    },
    [removeEntry.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addPinById.pending]: state => {
      state.loading = true;
    },
    [addPinById.fulfilled]: state => {
      state.loading = false;
      state.error = '';
    },
    [addPinById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addUserImage.pending]: state => {
      state.loading = true;
    },
    [addUserImage.fulfilled]: state => {
      state.loading = false;
      state.error = '';
    },
    [addUserImage.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addUserColor.pending]: state => {
      state.loading = true;
    },
    [addUserColor.fulfilled]: state => {
      state.loading = false;
      state.error = '';
    },
    [addUserColor.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default userSlice.reducer;
