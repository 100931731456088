/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <g fill={fill} stroke={stroke} strokeWidth="1.5">
      <path d="M15.001 16.998h-10a1 1 0 01-1-1v-13a1 1 0 011-1h6.841l4.163 4.162v9.841a1 1 0 01-1.004.997z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.002 6.16H12.84a1 1 0 01-1-1V1.999z"
      />
    </g>
  );
};

export default IconPath;
