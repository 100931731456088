import React, { useState } from 'react';
import { toUpper } from 'ramda';

import styled, { css } from 'styled-components';

const TabContainer = styled.div`
  align-items: flex-start;
  display: flex;
  ${props => {
    const { variant } = props;
    if (variant === 'BORDER') {
      return css`
        width: 100%;
      `;
    }
  }}
`;

const TabGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-self: flex-start;
  ${props => {
    const { variant } = props;
    if (variant === 'PRIMARY') {
      return css`
        padding: 4px;
        width: 100%;
        background: #f1f3f6;
        border-radius: 4px;
      `;
    }
    if (variant === 'SECONDARY') {
      return css`
        padding: 0px;
        width: 100%;
        background: #f1f2f6;
        border-radius: 6px;
      `;
    }
    if (variant === 'BORDER') {
      return css`
        padding: 0;
        background: none;
        width: 100%;
        padding-left: 6px;
        border-bottom: 1px solid #e3e5eb;
      `;
    }
    if (variant === 'DEFAULT') {
      return css`
        padding: 0;
        background: none;
      `;
    }
  }}
`;

const TabControl = ({ children, variant, active, ...props }) => {
  const [activeTab, setActiveTab] = useState(active);

  const config = {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    DEFAULT: 'DEFAULT',
  };

  const activeSetter = name => () => setActiveTab(name);

  const asTabItem = name => {
    const isActive = name === activeTab;
    const onClick = activeSetter(name);

    return {
      isActive,
      onClick,
      key: name,
    };
  };

  return (
    <TabContainer variant={config[toUpper(variant || 'DEFAULT')]}>
      <TabGroup variant={config[toUpper(variant || 'DEFAULT')]} {...props}>
        {children(asTabItem)}
      </TabGroup>
    </TabContainer>
  );
};

export { TabControl };
