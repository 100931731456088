/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91741 3.5C3.87031 3.5 3.31069 4.73328 4.00021 5.5213L8.40809 10.5589V16.0937C8.40809 16.258 8.50707 16.4062 8.65888 16.4691C8.81068 16.5319 8.98542 16.4972 9.10161 16.381L11.4731 13.5372C11.5493 13.4611 11.5921 13.3577 11.5921 13.25V10.5589L16 5.5213C16.6895 4.73328 16.1299 3.5 15.0828 3.5L4.91741 3.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
