import React, { useEffect } from 'react';
import { useRouter } from '../../utils/hooks/useRouter';
import { Loader, Button } from '../../common';
import { logoutUser } from '../../state/auth/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../state/auth/selectors';

import styled from 'styled-components';
import { media } from '../../utils/styles';

const Container = styled.div`
  padding: 24px;
  border: 1px solid #e3e5eb;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  width: 100%;
  position: relative;
  margin-top: 64px;
  flex-direction: column;
  ${media.sm`
    max-width: 600px;
  `}
`;

const Content = styled.div`
  p {
    margin-bottom: 16px;
  }
`;
const Logout = () => {
  const router = useRouter();
  const { loading } = authSelector;

  const isLoading = useSelector(loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  const onLogin = () => router.push('/login');

  return (
    <Container>
      {isLoading && <Loader />}
      <Content>
        <p>You have logged out</p>
      </Content>
      {!isLoading && (
        <Button variant="PRIMARY" onClick={onLogin}>
          Login
        </Button>
      )}
    </Container>
  );
};

export { Logout };
