/* eslint-disable react/prop-types */
import React from 'react';

const Blocked = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <g fill={fill}>
      <circle cx="10" cy="10" r="7.25" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M5.19992 14.8003L15.1999 5.20032"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </g>
  );
};

export default Blocked;
