import styled, { css } from 'styled-components';
import {
  heightFn,
  media,
  widthFn,
  paddingFn,
  colorFn,
  horizontalPushFn,
  verticalPushFn,
  verticalPullFn,
} from '../../utils/styles';

const bgColorFn = ({ bgColor }) =>
  bgColor &&
  css`
    background-color: ${bgColor};
  `;

const bgHoverFn = ({ bgHoverColor }) =>
  bgHoverColor &&
  css`
    &:focus,
    &:hover {
      background-color: ${bgHoverColor};
    }
  `;

const whiteVariant = ({ variant }) => {
  if (variant === 'white') {
    return css`
      .icon {
        stroke: black;
        fill: black;
      }
      text-indent: 20px;
      background-color: white;
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid #e3e5eb;
      &:hover {
        background-color: #e3e5eb;
        border: 1px solid #e3e5eb;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid #e2e7f1;
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
        border: 1px solid transparent;
      }
    `;
  }
};

const plainVariant = ({ variant }) => {
  if (variant === 'plain') {
    return css`
      .icon {
        stroke: black;
        fill: black;
      }
      text-indent: 24px;
      background-color: #edf0f6;
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid #e3e5eb;
      &:hover {
        background-color: #e2e7f1;
        border: 1px solid #e2e7f1;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid #e2e7f1;
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
        border: 1px solid transparent;
      }
    `;
  }
};

const disabledFn = ({ isDisabled }) =>
  isDisabled &&
  css`
    border: solid 1px transparent;
    background: #f5f5f8;
    cursor: not-allowed;
    color: #d6d5e4;
    &:focus {
      border: solid 1px transparent;
    }
    &:hover {
      background: #f5f5f8;
    }
  `;

const mediaStyle = css`
  &::focus {
    font-size: 16px;
  }
  ${media.sm`
      &::focus {
      font-size: 14px;
    }
  `}
  ${media.md`
      &::focus {
      font-size: 14px;
    }
  `}
  ${media.lg`
      &::focus {
      font-size: 14px;
    }
  `}
`;

const InputGroup = styled.div.attrs({ className: 'field-group' })`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${paddingFn};
  ${verticalPushFn};
  ${verticalPullFn};
  ${horizontalPushFn};
`;

const InputLabel = styled.span.attrs({ className: 'label' })`
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  margin-bottom: 0;
  font-size: 14px;
  color: #a9aeb9;
  ${props =>
    props.labelColor &&
    css`
      color: #1d2a43;
    `}
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
`;

const InputIcon = styled.span`
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  top: 14px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const TextArea = styled.textarea`
  max-height: 126px;
  height: 126px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f5f5f8;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 14px 16px;
  display: block;
  width: 100%;
  color: #8993a6;
  margin-top: 8px;
  resize: none;
  ${widthFn};
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid #4b658d;
  }
  &::placeholder {
    color: #9592a8;
  }
  ${props =>
    props.variant === 'form' &&
    css`
      background: #f7f8fa;
      border: 1px solid #eef1f8;
      outline: none;
      &:hover {
        background: #f7f8fa;
        border: 1px solid #eef1f8;
      }
      &:focus {
        background: #f7f8fa;
        border: 1px solid #eef1f8;
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
        border: 1px solid transparent;
      }
    `}
`;

const errorFn = ({ error }) =>
  error &&
  css`
    border: 1px solid #4b658d;
    &:focus {
      border: 1px solid #4b658d;
    }
  `;

const formVariant = ({ variant }) => {
  if (variant === 'form') {
    return css`
      background: #f7f8fa;
      border: 1px solid #eef1f8;
      outline: none;
      &:hover {
        background: #f7f8fa;
        border: 1px solid #eef1f8;
      }
      &:focus {
        background: #f7f8fa;
        border: 1px solid #eef1f8;
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
        border: 1px solid transparent;
      }
    `;
  }
};

const InputField = styled.input.attrs({ className: 'input-field' })`
  height: 48px;
  appearance: none;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f2f3f4;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  box-sizing: border-box;
  padding: 14px 16px 14px 10px;
  display: block;
  width: 100%;
  background-image: none;
  box-shadow: none;
  font-weight: normal;
  color: #211551;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e2e5ef;
  }
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid #4b658d;
    background-color: white;
  }
  &::placeholder {
    color: #9592a8;
    font-weight: normal;
  }
  ${colorFn};
  ${heightFn};
  ${widthFn};
  ${verticalPullFn};
  ${verticalPushFn};
  ${formVariant};
  ${plainVariant};
  ${whiteVariant};
  ${bgColorFn};
  ${errorFn};
  ${disabledFn};
  ${bgHoverFn};
  ${mediaStyle};
`;

export {
  InputGroup,
  TextArea,
  InputField,
  InputLabel,
  InputContainer,
  InputIcon,
};
