import React from 'react';
import { includes, toUpper, keys } from 'ramda';
import styled, { css } from 'styled-components';

const TextFunctions = css`
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const TextStyle = css`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  text-transform: none;
  line-height: 20px;
  user-select: none;
  ${props =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
    ${props =>
    props.horizontalPush &&
    !props.reset &&
    css`
      margin-left: ${props.horizontalPush};
    `}
  ${props => {
    const { verticalPush = '' } = props;
    const hasUnit = includes('px')(verticalPush);
    const determineUnit = hasUnit ? verticalPush : `${verticalPush}px`;
    if (verticalPush) {
      return css`
        margin-top: ${determineUnit};
      `;
    }
  }}
  ${props =>
    props.verticalPull &&
    !props.reset &&
    css`
      margin-bottom: ${props.verticalPull} !important;
    `}
    ${props =>
    props.reset &&
    css`
      margin: 0 !important;
    `}
  ${props => {
    const { size = '' } = props;
    const hasUnit = includes('px')(size);
    const determineUnit = hasUnit ? size : `${size}px`;
    if (size) {
      return css`
        font-size: ${determineUnit};
      `;
    }
  }}
  ${props => {
    const { lineHeight = '' } = props;
    const hasUnit = includes('px')(lineHeight);
    const determineUnit = hasUnit ? lineHeight : `${lineHeight}px`;
    if (lineHeight) {
      return css`
        line-height: ${determineUnit};
      `;
    }
  }}
`;

const Primary = styled.span`
  ${TextStyle};
  color: #1d2a43;
  ${TextFunctions};
`;

const Secondary = styled.span`
  ${TextStyle};
  color: #ff5a45;
  ${TextFunctions};
`;

const Variant = ({ variant = 'PRIMARY', children, ...props }) => {
  const config = {
    ['PRIMARY']: <Primary {...props}>{children}</Primary>,
    ['SECONDARY']: <Secondary {...props}>{children}</Secondary>,
  };

  if (!includes(toUpper(variant))(keys(config))) {
    return <Primary {...props}>{children}</Primary>;
  }

  return config[toUpper(variant)];
};

export { Variant };
