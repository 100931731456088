/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#FF5A45' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 2.5V1"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="11" r="8.25" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M8 1H12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 17C13.3137 17 16 14.3137 16 11H10V5C6.68629 5 4 7.68629 4 11C4 14.3137 6.68629 17 10 17Z"
        fill={stroke}
      />
    </svg>
  );
};

export default IconPath;
