import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = state => state.auth;

const loading = createSelector(selectAuthState, slice => slice.loading);
const user = createSelector(selectAuthState, slice => slice.user);
const persisted = createSelector(selectAuthState, slice => slice.persisted);
const authenticated = createSelector(
  selectAuthState,
  slice => slice.authenticated,
);

export const authSelector = {
  loading,
  user,
  persisted,
  authenticated,
};
