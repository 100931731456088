import styled, { css } from 'styled-components';

import { bgColorFn, widthFn, mtFn, mbFn } from '../../utils/styles';

const stepCircleCompleted = ({ isCompleted }) =>
  isCompleted &&
  css`
    background: #26a701;
    border: 1px solid #26a701;
    cursor: pointer;
    .stepText {
      display: none;
    }
  `;

const stepCircleActive = ({ isActive }) =>
  isActive &&
  css`
    box-shadow: 0px 0px 4px 2px rgb(75 101 141 / 30%);
    border: 1px solid #323232;
    background: white;
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
    .stepText {
      color: #323232;
    }
  `;

const stepItemCenter = ({ center }) =>
  center &&
  css`
    display: flex;
    justify-content: center;
    .stepDash {
      width: calc(100% - 40px);
      transform: translate(50%, 0%);
    }
  `;

const VisuallyHidden = styled.div`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  ${bgColorFn};
  ${widthFn};
  ${mtFn};
  ${mbFn};
`;

const listStyle = css`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StepCircle = styled.div`
  width: 24px;
  height: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid #999999;
  background: white;
  position: relative;
  z-index: 2;
  .stepText {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #999999;
  }
  ${stepCircleCompleted};
  ${stepCircleActive};
`;

const StepDash = styled.span`
  height: 2px;
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translate(0%, 0%);
  border-top: 1px dashed #999999;
  width: 100%;
  left: 40px;
  width: calc(100% - 50px);
`;

const StepText = styled.span`
  position: absolute;
  font-family: Ciutadella;
  margin: 0;
  padding: 0;
  width: 10px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`;

const StepItem = styled.li`
  ${listStyle};
  width: 100%;
  position: relative;
  ${stepItemCenter};
`;

const StepGroup = styled.ul`
  ${listStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export {
  Container,
  VisuallyHidden,
  StepItem,
  StepText,
  StepCircle,
  StepDash,
  StepGroup,
};
