import styled, { css } from 'styled-components';

const config = {
  width: '90',
};

const Container = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

const WeekList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100vw;
  width: 100%;
  justify-content: flex-start;
  flex: none;
  order: 1;
  flex-grow: 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border: none;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #dfdfe7;
    border: none;
    box-shadow: none;
  }
`;

const ItemLabel = styled.span.attrs({ className: 'date__strip-label' })`
  margin-bottom: 4px;
  color: #778092;
  font-weight: 500;
  text-align: center;
`;

const ItemDate = styled.span.attrs({ className: 'date__strip-date' })`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #c9ccd1;
  text-align: center;
`;

const ItemContainer = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 8px;
  width: ${config.width}px;
  max-width: ${config.width}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 17px;
  border-radius: 6px;
  border: 1px solid transparent;
  background: #f6f6f6;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  span {
    font-size: 12px;
  }
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    border: 1px solid #4b658d;
  }
  ${props =>
    props.isSelected &&
    css`
      background: #4b658d;
      .date__strip-label {
        color: white;
      }
      .date__strip-date {
        color: white;
      }
    `}
  ${props =>
    props.isActive &&
    css`
      background: #37c348;
      .date__strip-label {
        color: white;
      }
      .date__strip-date {
        color: white;
      }
    `}
`;

export { Container, ItemLabel, ItemDate, WeekList, ItemContainer };
