/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = 'none' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M4 17.2484H16C17.1046 17.2484 18 16.3529 18 15.2484V7.25543C18 6.70315 17.5523 6.25543 17 6.25543H3C2.44772 6.25543 2 6.70315 2 7.25543V15.2484C2 16.3529 2.89543 17.2484 4 17.2484Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M2 8.08697V3.75488C2 3.2026 2.44772 2.75488 3 2.75488H7.00163C7.39235 2.75488 7.74727 2.98243 7.91036 3.33748L9.05344 5.82611"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPath;
