import React from 'react';
import styled, { css } from 'styled-components';
import { prop, head } from 'ramda';

const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const PillGroup = styled.ul`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
`;

const PillItem = styled.div`
  padding: 8px 8px;
  display: inline-flex;
  box-sizing: border-box;

  border: 1px solid #e3e5eb;
  border-radius: 6px;

  max-width: 200px;
  transition: all 0.2s ease-in-out;
  line-height: 13px;
  font-size: 12px;
  /* color: white; */
  color: #778092;
  margin-left: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  background: white;
  margin-bottom: 4px;
  ${ellipsis}
  &:first-child {
    margin-left: 0;
  }
  ${props =>
    props.bgColorHover &&
    css`
      &:hover {
        background: ${props.bgColorHover};
      }
    `}
  ${props =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}
  ${props => css`
    font-weight: ${props.weight};
  `};
`;

const TotalsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #fff;
  padding: 8px;
  span {
    color: #979797;
  }
`;

const reduceTotalBreakTime = items => {
  const total = items.reduce((prev, current) => {
    console.log('prev item::', prev);
    // const collatedBreakMinutes = prev.breakMinutes + current.breakMinutes;
    // console.log('collatedBreakMinutes ::', collatedBreakMinutes);
    // return prev + current.breakMinutes;
  }, 0);
  return total;
};

const reduceTotalHourTime = items => {
  const total = items.reduce((prev, current) => {
    const start = prop('start')(current);
    const end = prop('end')(current);
    // hours
    const foundStartMatch = Number(head(start.split(':'))) || 0;
    const foundEndMatch = Number(head(end.split(':'))) || 0;
    const gatherFullHours = foundEndMatch - foundStartMatch;
    // more calculations
    return prev + gatherFullHours;
  }, 0);
  return total;
};

const reduceTotalBreakHours = items => {
  const total = items.reduce((prev, current) => {
    // const minute = prop('breakMinute')(current);
    const hour = prop('breakHour')(current);
    // hours
    // const foundMinuteMatch = Number(minute) || 0;
    const foundHourMatch = Number(hour) || 0;

    return prev + foundHourMatch;
  }, 0);
  return total;
};

const reduceTotalBreakMinutes = items => {
  const total = items.reduce((prev, current) => {
    const minutes = prop('breakMinute')(current);
    const foundMinuteMatch = Number(minutes) || 0;

    return prev + foundMinuteMatch;
  }, 0);
  return total;
};

const reduceTotalKilometres = items => {
  const total = items.reduce((prev, current) => {
    const gatherKilometres = Number(current.kilometers);
    return prev + gatherKilometres;
  }, 0);
  return total;
};

const Totals = ({ entries = [] }) => {
  const totalHours = reduceTotalHourTime(entries) || '00:00';
  const breakTime = reduceTotalBreakHours(entries) || '0';
  const breakTimeMinutes = reduceTotalBreakMinutes(entries) || '0';
  const totalKm = reduceTotalKilometres(entries);

  return (
    <TotalsContainer>
      <PillGroup>
        <PillItem>km: {totalKm}</PillItem>
        <PillItem>Hours: {totalHours}</PillItem>
        <PillItem>
          Break: {breakTime}:{breakTimeMinutes}
        </PillItem>
      </PillGroup>
    </TotalsContainer>
  );
};

export { Totals };
