/* eslint-disable react/prop-types */
import React from 'react';

const Eamil = props => {
  const { fill = '#A9AEB9', stroke = 'none' } = props;
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Icons/ico-email"
        stroke={stroke}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M2.5,6.6062 L2.866,6.8402 L10,11.5842 L17.5,6.8032 L17.5,13.7502 C17.5,15.0442 16.451,16.0942 15.156,16.0942 L15.156,16.0942 L4.844,16.0942 C3.549,16.0942 2.5,15.0442 2.5,13.7502 L2.5,13.7502 L2.5,6.6062 Z M15.1564,3.90619244 C15.9414,3.9042 16.6754,4.2992 17.1064,4.9562 L17.1064,4.9562 L10.0004,9.6342 L2.7904,5.1342 C3.1974,4.3782 3.9854,3.9072 4.8434,3.90619244 L4.8434,3.90619244 Z"
          id="Combined-Shape"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Eamil;
