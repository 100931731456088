/* eslint-disable react/prop-types */
import React from 'react';

const check = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M5.45455 2.75H14.5455C15.9258 2.75 17.1683 3.93907 17.25 5.47339V14.5455C17.25 16.0403 16.0403 17.25 14.5455 17.25H5.45455C3.95967 17.25 2.75 16.0403 2.75 14.5455V5.45455C2.75 3.95967 3.95967 2.75 5.45455 2.75Z"
        stroke={stroke}
        className="outline"
        strokeWidth="1.5"
      />
      <path
        d="M6 11L8 13L14 7"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default check;
