import React, { useContext } from 'react';
import { useRouter } from '../../../../utils/hooks/useRouter';
import { Icon } from '../../../../common';
import { values, prop } from 'ramda';
import { Context } from '../../Provider';
import { useSelector } from 'react-redux';
import {
  MenuFooterContainer,
  FooterGroup,
  FooterItem,
  FooterIcon,
  FooterText,
} from './MenuFooter.styled';

// useEffect(() => {
//   dispatch(persistUser());
// }, []);

const isNext = true;
const baseUrl = isNext ? '' : './public/';

const imageConfig = type =>
  ({
    THUMB: `${baseUrl}assets/exercises/thumb.jpg`,
  }[type || 'THUMB']);

import styled, { css, keyframes } from 'styled-components';
import { authSelector } from '../../../../state/auth/selectors';

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const UserContainer = styled.div`
  margin-top: 16px;
  border-top: 1px solid #e3e5eb;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  width: 100%;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  .isExpanded & {
    display: flex;
    animation-name: ${fadeIn};
  }
`;

const UserImage = styled.div`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  min-width: 32px;
  ${props =>
    props.imageSrc &&
    css`
      background-image: ${`url(${props.imageSrc})`};
    `}
`;
const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 8px;
`;

const UserName = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #121b2d;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const UserEmail = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #a9aeb9;
  font-weight: 400;
  margin: 0;
  padding: 0;
`;

const { user } = authSelector;

const MenuFooterUser = ({ showIcon = false }) => {
  const userObject = useSelector(user);
  const email = prop('email')(userObject);
  const displayName = prop('displayName')(userObject);
  return (
    <UserContainer>
      {showIcon && <UserImage imageSrc={imageConfig('THUMB')} />}
      <UserContent>
        {displayName && <UserName>{displayName || 'Admin'}</UserName>}
        {email && <UserEmail>{email}</UserEmail>}
      </UserContent>
    </UserContainer>
  );
};

const MenuFooter = ({ options = {} }) => {
  const [state] = useContext(Context);
  const showIcon = false;

  const { menuExpanded: isExpanded } = state;
  const router = useRouter();

  const onPush = route => router.push(route);

  const renderFooterOptions = () => {
    const list = values(options) || [];
    if (!list || list.length <= 0) return null;
    return (
      <FooterGroup>
        {list.map(item => (
          <FooterItem
            key={item.id}
            onClick={() => onPush(item.route)}
            className="FooterItem"
          >
            <FooterIcon>
              <Icon name={item.icon} size={20} />
            </FooterIcon>

            {isExpanded && (
              <FooterText className="FooterText">{item.label}</FooterText>
            )}
          </FooterItem>
        ))}
      </FooterGroup>
    );
  };

  return (
    <MenuFooterContainer
      isExpanded={isExpanded}
      className={isExpanded ? 'isExpanded' : ''}
    >
      {renderFooterOptions()}
      <MenuFooterUser showIcon={showIcon} />
    </MenuFooterContainer>
  );
};

MenuFooter.defaultProps = {
  options: {
    logout: {
      id: 'logout',
      route: '/logout',
      label: 'Log Out',
      icon: 'LEAVE',
    },
  },
};

export default MenuFooter;
