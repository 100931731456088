import React, { useContext, useState } from 'react';
import { useRouter } from '../../../../utils/hooks/useRouter';
import { uuid } from '../../../../utils/uuid';
import { Icon } from '../../../../common';
import { values } from 'ramda';
import { Context } from '../../Provider';
import { useHistory } from 'react-router-dom';

import {
  MenuBodyContainer,
  MenuCustomIcon,
  MenuCloseContainer,
  MenuGroup,
  MenuItem,
  MenuIcon,
  MenuText,
  MenuClose,
} from './MenuBody.styled';

const MenuBody = ({ options = {}, callback = () => {} }) => {
  const [state, dispatch] = useContext(Context);
  const [selectedId, setSelectedId] = useState('users');
  const router = useRouter();
  const history = useHistory();

  const { menuExpanded: isExpanded } = state;

  const onToggle = () => {
    if (isExpanded) {
      dispatch({ type: 'CLOSE_MENU' });
    } else {
      dispatch({ type: 'OPEN_MENU' });
    }
  };

  const onPush = ({ id, route }) => {
    setSelectedId(id);
    history.push(route);
    // router.push(route);
  };

  const renderBodyClose = () => {
    if (isExpanded) return null;
    return (
      <MenuCloseContainer>
        <MenuClose onClick={onToggle}>
          <Icon rotate={isExpanded ? 180 : 0} name="ARROWRIGHT" size={20} />
        </MenuClose>
      </MenuCloseContainer>
    );
  };

  const renderBodyOptions = () => {
    const list = values(options) || [];
    if (!list || list.length <= 0) return null;
    return (
      <MenuGroup>
        {list.map((item, index) => (
          <MenuItem
            key={`${index}-${uuid()}`}
            isActive={item.id === selectedId}
            onClick={() => onPush({ route: item.route, id: item.id })}
            className="MenuItem"
          >
            <MenuIcon>
              <Icon stroke="white" name={item.icon} size={20} />
            </MenuIcon>
            {isExpanded && (
              <MenuText className="MenuText">{item.label}</MenuText>
            )}
            {item.id === 'settings' && isExpanded && (
              <MenuCustomIcon className={item.icon}>
                <Icon name={item.icon} size={20} />
              </MenuCustomIcon>
            )}
          </MenuItem>
        ))}
      </MenuGroup>
    );
  };

  return (
    <MenuBodyContainer
      isExpanded={isExpanded}
      className={isExpanded ? 'isExpanded' : ''}
    >
      {renderBodyClose()}
      {renderBodyOptions()}
    </MenuBodyContainer>
  );
};

MenuBody.defaultProps = {
  options: {
    dashboard: {
      id: 'users',
      route: '/',
      label: 'Dashboard',
      icon: 'TROPHY',
    },
    users: {
      id: 'users',
      route: '/users',
      label: 'Users',
      icon: 'USER',
    },
    clients: {
      id: 'clients',
      route: '/clients',
      label: 'Clients',
      icon: 'CLIPBOARD',
    },
  },
};

export default MenuBody;
