import { createSelector } from '@reduxjs/toolkit';

const selectSettingsState = state => state.settings;

const loading = createSelector(selectSettingsState, slice => slice.loading);
const range = createSelector(selectSettingsState, slice => slice.range);

export const settingsSelector = {
  loading,
  range,
};
