import React, { useContext } from 'react';
import { media } from '../../../../utils/styles';
import styled from 'styled-components';
import { Context } from '../../Provider';
import { useRouter } from '../../../../utils/hooks/useRouter';
import { Icon, Modal } from '../../../../common';

const MenuMobileContainer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  ${media.sm`
    display :none;`}
`;

const MenuMobileHeader = styled.div``;

const MenuMobileButton = styled.button``;

// open modal view

const MenuMobile = () => {
  const [state, dispatch] = useContext(Context);
  const isExpanded = false;
  // const { menuExpanded: isExpanded } = state;
  const router = useRouter();

  const onToggle = () => {};
  // const onToggle = () => {
  //   if (isExpanded) {
  //     dispatch({ type: 'CLOSE_MENU' });
  //   } else {
  //     dispatch({ type: 'OPEN_MENU' });
  //   }
  // };

  return (
    <MenuMobileContainer>
      <MenuMobileHeader>
        <MenuMobileButton onClick={onToggle}>
          <Icon name="NAV_ARROW" size={20} />
        </MenuMobileButton>
      </MenuMobileHeader>
      {isExpanded && (
        <Modal>
          <button onClick={() => router.push('/workers')}>Workers</button>
          <button onClick={() => router.push('/workers')}>Clients</button>
        </Modal>
      )}
    </MenuMobileContainer>
  );
};

export default MenuMobile;
