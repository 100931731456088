import React, { useContext } from 'react';
import { Context } from '../../Provider';
import { Icon } from '../../../../common';
import {
  LogoContainer,
  MenuHeaderContainer,
  MenuClose,
} from './MenuHeader.styled';
import LogoIcon from './assets/Logo';

const MenuHeader = () => {
  const [state, dispatch] = useContext(Context);
  const showLogo = false;
  const { menuExpanded: isExpanded } = state;

  const onToggle = () => {
    if (isExpanded) {
      dispatch({ type: 'CLOSE_MENU' });
    } else {
      dispatch({ type: 'OPEN_MENU' });
    }
  };

  return (
    <MenuHeaderContainer
      isExpanded={isExpanded}
      className={isExpanded ? 'isExpanded' : ''}
    >
      <LogoContainer onClick={onToggle}>
        {showLogo && <LogoIcon />}
      </LogoContainer>

      {isExpanded && (
        <MenuClose onClick={onToggle}>
          <Icon rotate={isExpanded ? 180 : 0} name="ARROWRIGHT" size={20} />
        </MenuClose>
      )}
    </MenuHeaderContainer>
  );
};

export default MenuHeader;
