import React, { useEffect } from 'react';
import { Modal, Loader } from '../../../../common';
import styled from 'styled-components';
import { media } from '../../../../utils/styles';

const ModalContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  padding: 24px;
  flex-direction: column;

  background: transparent;
  border-radius: 10px;
  /* box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.15); */
  box-sizing: border-box;
  width: 100%;
  left: 50%;
  top: 50%;
  position: fixed;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%);

  z-index: 999;
  max-width: 250px;
  ${media.xs`
    max-width: 250px;
    min-width: 250px;
    `}
`;

const Loading = ({ callback = () => {} }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Modal type="SECONDARY" callback={value => callback(value)}>
      <ModalContainer>
        <Loader />
      </ModalContainer>
    </Modal>
  );
};

export { Loading };
