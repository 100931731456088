import React, { useState, useContext } from 'react';
import { Button, ErrorList, Steps } from '../../../common';

import { Calendar } from './Calendar';
import styled, { css } from 'styled-components';
import { format, addDays, getDay, subDays, getSeconds } from 'date-fns/fp';
import { parse } from 'date-fns';
import { getDayOfWeek } from './helpers';
import { prop } from 'ramda';
import { uuid } from '../../../utils/uuid';
import { Context } from '../../Layout/Provider';
import { useDispatch, useSelector } from 'react-redux';
import { addEntry, removeEntry } from '../../../state/user/reducers';
import { settingsSelector } from '../../../state/settings/selectors';
import { media } from '../../../utils/styles';
import {
  STEP_SELECT_DATE,
  STEP_SELECT_START,
  STEP_SELECT_END,
  STEP_SELECT_BREAK,
  STEP_SELECT_KILOMETERS,
  STEP_SELECT_SUBMIT,
  STEP_SELECT_UPDATE,
  STEP_SELECT_RESET,
} from '../../Layout/Provider/Reducer';

import { DayItem } from './DayItem';
import { Container, WeekList } from './DayItem/DayItem.styled';

const defaultSteps = [
  { id: STEP_SELECT_DATE, value: STEP_SELECT_DATE },
  { id: STEP_SELECT_START, value: STEP_SELECT_START },
  { id: STEP_SELECT_END, value: STEP_SELECT_END },
  { id: STEP_SELECT_BREAK, value: STEP_SELECT_BREAK },
  { id: STEP_SELECT_KILOMETERS, value: STEP_SELECT_KILOMETERS },
  { id: STEP_SELECT_SUBMIT, value: STEP_SELECT_SUBMIT },
];

const HeadingLine = styled.h2`
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin: 0;
  margin-bottom: 16px;
  margin-top: 16px;
  border-top: 1px solid #e0e0e0;
  padding-top: 16px;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EntriesBody = styled.div`
  padding: 16px;
  position: relative;
`;

const Entry = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background: blue;
  overflow: hidden;
  padding: 4px;
  margin-top: 8px;
  &:first-child {
    margin: 0;
  }
  background: #9ec67a;
  border: 1px solid #76945c;
  /* ${props =>
    props.day.toUpperCase() === 'MONDAY' &&
    css`
      background: #9ec67a;
      border: 1px solid #76945c;
    `}
  ${props =>
    props.day.toUpperCase() === 'TUESDAY' &&
    css`
      background: #60b6ba;
      border: 1px solid #3b7e81;
    `}
  ${props =>
    props.day.toUpperCase() === 'WEDNESDAY' &&
    css`
      background: #d79e76;
      border: 1px solid #977156;
    `}
  ${props =>
    props.day.toUpperCase() === 'THURSDAY' &&
    css`
      background: #6088ba;
      border: 1px solid #3c5573;
    `}
  ${props =>
    props.day.toUpperCase() === 'FRIDAY' &&
    css`
      background: #e91e63;
      border: 1px solid #991b46;
    `}
  ${props =>
    props.day.toUpperCase() === 'SATURDAY' &&
    css`
      background: #ad60ba;
      border: 1px solid #884d84;
    `}
  ${props =>
    props.day.toUpperCase() === 'SUNDAY' &&
    css`
      background: #ad60ba;
      border: 1px solid #884d84;
    `} */
`;

const StepWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EntryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  padding-right: 8px;
  margin-right: 8px;
  font-size: 14px;
  flex: 1;
  ${media.md`
  font-size: 16px;
  padding-right: 16px;
  margin-right: 16px;
  `}
  &:nth-last-child(2) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
`;

const EntryTitle = styled.div`
  min-width: 50px;
  ${media.sm`
    min-width: 100px;
  `}
  span {
    font-size: 16px;
    line-height: 40px;
    color: white;
    margin: 0;
    text-align: center;
    &.sm {
      display: block;
    }
    &.md {
      display: none;
    }
    ${media.sm`
      &.sm {
        display: block;
      }
      &.md {
        display: none;
      }
    `}
    ${media.md`
      &.sm {
        display: none;
      }
      &.md {
        display: block;
      }
    `}
  }
  text-transform: uppercase;
`;

const EntryOutput = styled.div`
  color: white;
  span.km {
    font-size: 12px;
    font-weight: 600;
    padding-left: 2px;
  }
  span.circle {
    position: relative;
    margin-right: 8px;
    top: -2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 4px;
    display: inline-block;
    background: #fff;
    line-height: 10px;
    border: 1px solid transparent;
    color: #666;
    text-align: center;
    font-size: 8px;
  }
`;

const EntryControl = styled.div`
  display: none;
  ${media.md`
  display: block;
  `}
  button {
    border: none;
    background-color: transparent;
    svg {
      path {
        stroke: white;
      }
      line {
        stroke: white;
      }
    }
  }
`;

const StepItemFieldGroup = styled.div``;

const StepItemField = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  padding-top: 16px;
  label {
    margin-bottom: 16px;
  }
  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .NumberInput {
    flex: 1;
    margin-right: 8px;
  }
`;

const EntryContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const StepFooter = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 24px;
`;

const SelectView = styled.div`
  margin-bottom: 24px;
`;

const EntryView = styled.div`
  padding: 10px;
  background-color: #4b658d;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  border-radius: 6px;
  span {
    color: white;
    font-weight: 700;
    padding-left: 4px;
  }
  p {
    display: none;
    margin: 0;
    color: white;
  }
`;

const TimeInput = styled.input.attrs({ type: 'time' })`
  padding: 9px;
  border-radius: 4px;
  border: none;
  transition: all 0.1s ease-in-out;
  width: 100%;
  outline: none;
  color: #a9aeb9;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #f7f8fa;
  background: #f7f8fa;
  &:focus {
    border: 1px solid #4b658d;
    outline: none;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid #4b658d;
  }
`;

const EntriesContainer = styled.div``;

const StepSelectDate = ({ dispatch, state, ...props }) => {
  const dayEntries = prop('entries')(props);
  const dateState = prop('date')(state.entry) || new Date();
  const dateDay = prop('day')(state.entry);

  const getSevenDays = addDays(7)(new Date());
  const getPreviousSevenDays = subDays(7)(new Date());
  const [errors, setList] = useState([]);
  const [date, setDate] = useState(dateState);

  const onCalenderChange = date => {
    const foundWeekDay = getDayOfWeek(date); // MONDAY etc

    const matchedDay = dayEntries.reduce(
      (accumulator, entry) => accumulator || entry.day === foundWeekDay,
      false,
    );

    if (matchedDay) {
      console.log('matched weekday');
      setList([
        {
          name: 'error',
          message:
            'Ooops, there was a problem, that day has already been selected.',
        },
      ]);
      return;
    }
    setDate(date);
  };

  const removeItem = name => {
    setList(errors.filter(item => item.name !== name));
  };

  const onNext = e => {
    e.preventDefault();
    if (!date) {
      setList([
        { name: 'error', message: 'An error has occured, refresh page' },
      ]);
      return;
    }

    dispatch({
      type: STEP_SELECT_DATE,
      data: { date: date, day: getDayOfWeek(date) },
    });
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_START });
    setList([]);
  };

  const renderErrors = errors => {
    if (!errors || errors.length <= 0) return null;
    return (
      <ModalContentWrapper style={{ marginTop: '16px' }}>
        <ErrorList
          haystack={errors}
          callback={removeItem}
          hasClose={true}
          needles={['error']}
        />
      </ModalContentWrapper>
    );
  };

  return (
    <>
      <Calendar
        onChange={onCalenderChange}
        value={date}
        maxDate={getSevenDays}
        minDate={getPreviousSevenDays}
      />

      <SelectView>
        <EntryView>
          <p>Day Selected:</p>
          <span>{format('yyy-MM-dd')(date)}</span>
        </EntryView>
        <EntryView>
          <p>Day of week: </p> <span>{getDayOfWeek(date)}</span>
        </EntryView>
      </SelectView>
      {renderErrors(errors)}
      <StepFooter>
        <Button
          variant="OUTLINE"
          disabled={errors.length > 0}
          style={{ marginLeft: 'auto' }}
          onClick={onNext}
        >
          Next
        </Button>
      </StepFooter>
    </>
  );
};

const StepStartTime = ({ dispatch, state }) => {
  const startTimeState = prop('start')(state.entry) || '00:00';
  const [localStartTime, setLocalStartTime] = useState(startTimeState);
  const [errors, setList] = useState([]);

  const handleStartChange = e => {
    const { value } = e.target;
    setLocalStartTime(value);
  };

  const removeItem = name => {
    setList(errors.filter(item => item.name !== name));
  };

  const onPrevious = e => {
    e.preventDefault();
    // Back to date select
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_DATE });
  };

  const onNext = e => {
    e.preventDefault();
    if (!localStartTime) {
      setList([
        {
          name: 'error',
          message:
            'An error has occured, please add the start time.  Press the clock icon above',
        },
      ]);
      return;
    }
    setList([]);
    dispatch({ type: STEP_SELECT_START, data: localStartTime });
    // Next Step
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_END });
  };

  const renderErrors = errors => {
    if (!errors || errors.length <= 0) return null;
    return (
      <div style={{ marginTop: '16px' }}>
        <ErrorList
          haystack={errors}
          callback={removeItem}
          hasClose={true}
          needles={['error']}
        />
      </div>
    );
  };

  return (
    <ModalContentWrapper>
      <SelectView>
        <EntryView>
          <span>Start Time: {localStartTime}</span>
        </EntryView>
      </SelectView>
      <TimeInput value={localStartTime} onChange={handleStartChange} />
      {renderErrors(errors)}
      <StepFooter>
        <Button variant="OUTLINE" onClick={onPrevious}>
          Previous
        </Button>
        <Button variant="OUTLINE" disabled={errors.length > 0} onClick={onNext}>
          Next
        </Button>
      </StepFooter>
    </ModalContentWrapper>
  );
};

const StepEndTime = ({ dispatch, state }) => {
  const endTimeState = prop('end')(state.entry) || '00:00';
  const [localEndTime, setLocalEndTime] = useState(endTimeState);
  const [errors, setList] = useState([]);
  const handleEndChange = e => setLocalEndTime(e.target.value);

  const removeItem = name => {
    setList(errors.filter(item => item.name !== name));
  };

  const onPrevious = e => {
    e.preventDefault();
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_START });
  };

  const onNext = e => {
    e.preventDefault();
    if (!localEndTime) {
      setList([
        { name: 'error', message: 'An error has occured, refresh page' },
      ]);
      return;
    }
    setList([]);
    dispatch({ type: STEP_SELECT_END, data: localEndTime });
    // NEXT STEP
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_BREAK });

    setList([]);
  };

  const renderErrors = errors => {
    if (!errors || errors.length <= 0) return null;
    return (
      <div style={{ marginTop: '16px' }}>
        <ErrorList
          haystack={errors}
          callback={removeItem}
          hasClose={true}
          needles={['error']}
        />
      </div>
    );
  };

  return (
    <ModalContentWrapper>
      <SelectView>
        <EntryView>
          <span>End Time: {localEndTime}</span>
        </EntryView>
      </SelectView>
      <TimeInput value={localEndTime} onChange={handleEndChange} />
      {renderErrors(errors)}
      <StepFooter>
        <Button variant="OUTLINE" onClick={onPrevious}>
          Previous
        </Button>
        <Button variant="OUTLINE" onClick={onNext}>
          Next
        </Button>
      </StepFooter>
    </ModalContentWrapper>
  );
};

const StepBreakTime = ({ dispatch, state }) => {
  const getStateHours = prop('breakHour')(state.entry);
  const getStateMinutes = prop('breakMinute')(state.entry);

  const [errors, setList] = useState([]);
  const [localBreakHourTime, setLocalBreakHourTime] = useState(
    getStateHours || '0',
  );
  const [localBreakMinuteTime, setLocalBreakMinuteTime] = useState(
    getStateMinutes || '0',
  );

  const handleBreakHourTime = value => setLocalBreakHourTime(value);
  const handleBreakMinuteTime = value => setLocalBreakMinuteTime(value);

  const removeItem = name => {
    setList(errors.filter(item => item.name !== name));
  };

  const onPrevious = e => {
    e.preventDefault();
    // Previous Step
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_END });
  };

  const onNext = e => {
    e.preventDefault();
    if (!localBreakHourTime || !localBreakMinuteTime) {
      setList([
        {
          name: 'error',
          message:
            'An error has occured, please enter break time in hours and minutes',
        },
      ]);
      return;
    }
    setList([]);
    dispatch({
      type: STEP_SELECT_BREAK,
      data: {
        breakHour: localBreakHourTime,
        breakMinute: localBreakMinuteTime,
      },
    });
    // NEXT STEP
    dispatch({
      type: STEP_SELECT_UPDATE,
      data: STEP_SELECT_KILOMETERS,
    });
  };

  const renderErrors = errors => {
    if (!errors || errors.length <= 0) return null;
    return (
      <div style={{ marginTop: '16px' }}>
        <ErrorList
          haystack={errors}
          callback={removeItem}
          hasClose={true}
          needles={['error']}
        />
      </div>
    );
  };

  return (
    <>
      <SelectView>
        <EntryView>
          <span>
            Break time: {localBreakHourTime}:{localBreakMinuteTime}
          </span>
        </EntryView>
      </SelectView>
      <StepItemFieldGroup>
        <StepItemField>
          <NumberInput
            callback={handleBreakHourTime}
            value={localBreakHourTime}
            unit={
              localBreakHourTime && Number(localBreakHourTime) > 1
                ? 'Hours'
                : 'Hour'
            }
            step={1}
            max={40}
          />

          <NumberInput
            callback={handleBreakMinuteTime}
            value={localBreakMinuteTime}
            unit={
              localBreakMinuteTime && Number(localBreakMinuteTime) >= 2
                ? 'Minutes'
                : 'Minute'
            }
            step={5}
            max={60}
          />
        </StepItemField>
      </StepItemFieldGroup>
      {renderErrors(errors)}
      <StepFooter>
        <Button variant="OUTLINE" onClick={onPrevious}>
          Previous
        </Button>
        <Button variant="OUTLINE" onClick={onNext}>
          Next
        </Button>
      </StepFooter>
    </>
  );
};

const StepKilometers = ({ dispatch, state }) => {
  const stateKilometers = prop('kilometers')(state.entry) || 0;
  const [localKilometers, setLocalKilometers] = useState(stateKilometers);
  const handleKilometers = value => setLocalKilometers(value);

  const onPrevious = e => {
    e.preventDefault();
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_BREAK });
  };

  const onNext = e => {
    e.preventDefault();

    dispatch({ type: STEP_SELECT_KILOMETERS, data: localKilometers });
    // NEXT STEP
    dispatch({
      type: STEP_SELECT_UPDATE,
      data: STEP_SELECT_SUBMIT,
    });
  };

  return (
    <ModalContentWrapper>
      <SelectView>
        <EntryView>
          <span className="EntryTitle">km:</span>
          <span>{localKilometers}</span>
        </EntryView>
      </SelectView>

      <NumberInput
        callback={handleKilometers}
        value={localKilometers}
        unit={
          localKilometers && localKilometers > 1 ? 'Kilometers' : 'Kilometer'
        }
        step={5}
        max={300}
      />

      <StepFooter>
        <Button variant="OUTLINE" onClick={onPrevious}>
          Previous
        </Button>
        <Button variant="OUTLINE" onClick={onNext}>
          Next
        </Button>
      </StepFooter>
    </ModalContentWrapper>
  );
};

const StepSubmit = ({ dispatch, state = {}, uid, callback }) => {
  const [errors, setList] = useState([]);

  const dispatchAction = useDispatch();

  const removeItem = name => {
    setList(errors.filter(item => item.name !== name));
  };

  const renderErrors = errors => {
    if (!errors || errors.length <= 0) return null;
    return (
      <div style={{ marginTop: '16px' }}>
        <ErrorList
          haystack={errors}
          callback={removeItem}
          hasClose={true}
          needles={['error']}
        />
      </div>
    );
  };

  const onPrevious = e => {
    e.preventDefault();
    dispatch({ type: STEP_SELECT_UPDATE, data: STEP_SELECT_KILOMETERS });
  };

  const onSubmit = () => {
    console.log({ entry: state.entry });
    const {
      entry: { date, start, end, breakHour, breakMinute, kilometers },
    } = state;

    if (!uid || !date || !start || !end || !breakHour) {
      setList([
        { name: 'error', message: 'An error has occured, refresh page' },
      ]);
      return;
    }
    dispatchAction(
      addEntry({
        uid: uid,
        item: {
          id: uuid(),
          date: format('yy-MM-dd')(date),
          start: start,
          end: end,
          breakHour: breakHour,
          breakMinute: breakMinute,
          kilometers: kilometers,
          createdBy: 'admin',
          jobId: 'none',
        },
      }),
    );

    dispatch({ type: STEP_SELECT_RESET });
    callback();
  };

  return (
    <ModalContentWrapper>
      {renderErrors(errors)}
      <StepFooter>
        <Button variant="OUTLINE" onClick={onPrevious}>
          Previous
        </Button>
        <Button
          variant="PRIMARY"
          onClick={onSubmit}
          style={{ textAlign: 'center', justifyContent: 'center' }}
        >
          <span>Create Entry</span>
        </Button>
      </StepFooter>
    </ModalContentWrapper>
  );
};

const EntryStep = ({ callback = () => {}, uid, entries = [] }) => {
  const [state, dispatch] = useContext(Context);
  const { entryStep } = state;

  console.log('uid ::', uid);

  return (
    <>
      <StepWrapper>
        <Steps
          config={{ align: 'center' }}
          steps={defaultSteps}
          currentStep={entryStep}
        />
      </StepWrapper>
      <RenderStep
        entries={entries}
        step={entryStep}
        dispatch={dispatch}
        callback={callback}
        state={state}
        uid={uid}
      />
    </>
  );
};

const RenderStep = ({ step, ...props }) =>
  ({
    [STEP_SELECT_DATE]: <StepSelectDate {...props} />,
    [STEP_SELECT_START]: <StepStartTime {...props} />,
    [STEP_SELECT_END]: <StepEndTime {...props} />,
    [STEP_SELECT_BREAK]: <StepBreakTime {...props} />,
    [STEP_SELECT_KILOMETERS]: <StepKilometers {...props} />,
    [STEP_SELECT_SUBMIT]: <StepSubmit {...props} />,
  }[step || STEP_SELECT_DATE]);

const getWeekDay = date => {
  const day = getDay(date);
  const matchedDay = entry =>
    ({
      ['0']: 'SUNDAY',
      ['1']: 'MONDAY',
      ['2']: 'TUESDAY',
      ['3']: 'WEDNESDAY',
      ['4']: 'THURSDAY',
      ['5']: 'FRIDAY',
      ['6']: 'SATURDAY',
    }[entry || 0]);
  return matchedDay(day);
};

const sortedEntries = entries =>
  [...entries].sort((a, b) =>
    getSeconds(a.date) < getSeconds(b.date)
      ? -1
      : getSeconds(a.date) > getSeconds(b.date)
      ? 1
      : 0,
  );

const getDaysArray = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

const mergeDateView = list => entries => {
  return list.reduce((prev, curr) => {
    const props = entries.filter(i => i.date === format('yy-MM-dd')(curr))[0];
    const current = {
      label: getWeekDay(curr),
      number: format('dd')(curr),
      date: format('yy-MM-dd')(curr),
      active:
        entries.filter(i => i.date === format('yy-MM-dd')(curr)).length > 0,
      ...props,
    };
    return [...prev, current];
  }, []);
};

const createChunk = inputArray => {
  const perChunk = 7;
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return result;
};

const WeekItemContainer = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100&;
  flex-direction: column;
  flex: 1;
  margin-bottom: 8px;
`;

const DayGroup = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: none;
  justify-content: flex-start;
`;

const WeekItem = ({ week = [], callback = () => {}, selectedEntry = null }) => (
  <WeekItemContainer className="WeekItem">
    <DayGroup className={`DayGroup`}>
      {week.map((item, index) => (
        <DayItem
          onClick={() => callback(item, index)}
          key={index}
          isSelected={selectedEntry && selectedEntry.date === item.date}
          label={item.label}
          date={item.number}
          isActive={item.active}
        />
      ))}
    </DayGroup>
  </WeekItemContainer>
);

const Entries = ({ entries = [], ...props }) => {
  const { range } = settingsSelector;
  const rangeSelector = useSelector(range);
  const { start, end } = rangeSelector;

  const parsedStartDate = parse(start, 'yyyy-MM-dd', new Date());
  const parsedEndDate = parse(end, 'yyyy-MM-dd', new Date());

  const weekList = createChunk(
    mergeDateView(getDaysArray(parsedStartDate, parsedEndDate))(entries),
  );

  console.log('weeklist ::', weekList);

  const renderItems = () => {
    if (!weekList || weekList.length <= 0) return null;
    return (
      <WeekList className="WeekList">
        {weekList.map((week, i) => (
          <WeekItem week={week} key={i} index={i} {...props} />
        ))}
      </WeekList>
    );
  };

  return (
    <>
      <EntriesBody>
        <EntriesContainer>
          <Container>{renderItems()}</Container>
        </EntriesContainer>
      </EntriesBody>
    </>
  );
};

export { Entries };
