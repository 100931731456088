/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = 'none' } = props;
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g stroke={stroke} strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M8.2188,14.3356 C8.5418,14.0776 8.5948,13.6066 8.3358,13.2816 L6.3108,10.7516 L15.2498,10.7516 C15.6638,10.7516 15.9998,10.4156 15.9998,10.0016 C15.9998,9.5866 15.6638,9.2516 15.2498,9.2516 L6.3098,9.2516 L8.3358,6.7186 C8.5948,6.3936 8.5418,5.9226 8.2188,5.6646 C7.8948,5.4056 7.4238,5.4586 7.1648,5.7816 L4.1648,9.5316 C4.1358,9.5676 4.1208,9.6096 4.0998,9.6486 C4.0848,9.6766 4.0668,9.7006 4.0548,9.7306 C4.0208,9.8166 3.9998,9.9076 3.9998,10.0006 L3.9998,10.0006 L3.9998,10.0016 C3.9998,10.0026 4.0008,10.0036 4.0008,10.0056 C4.0018,10.0956 4.0218,10.1836 4.0538,10.2686 C4.0678,10.3026 4.0878,10.3306 4.1058,10.3616 C4.1258,10.3976 4.1378,10.4366 4.1648,10.4686 L7.1648,14.2186 C7.4238,14.5426 7.8948,14.5946 8.2188,14.3356"
          id="Colour"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconPath;
