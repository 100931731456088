import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './auth/reducers';
import userReducer from './user/reducers';
import settingsReducer from './settings/reducers';
import clientReducer from './client/reducers';

export default configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    client: clientReducer,
    settings: settingsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
