import React, { useState } from 'react';

import { TabControl, TabUnderlined } from '../../../../common';
import styled from 'styled-components';

const ControlContainer = styled.div`
  width: 100%;
`;

const Controls = ({ callback = () => {}, active = 'details' }) => (
  <ControlContainer>
    <TabControl variant="border" active={active}>
      {asTab => [
        <TabUnderlined {...asTab('details')} action={() => callback('details')}>
          Details
        </TabUnderlined>,
        <TabUnderlined
          {...asTab('notifications')}
          action={() => callback('notifications')}
        >
          Notifications
        </TabUnderlined>,
      ]}
    </TabControl>
  </ControlContainer>
);

export { Controls };
