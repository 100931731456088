import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="2.75"
        y="3.75"
        width="14.5"
        height="14.5"
        rx="1.25"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M2.75 5C2.75 4.30964 3.30964 3.75 4 3.75H16C16.6904 3.75 17.25 4.30964 17.25 5V7.25H2.75V5Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M5.55713 3.44232L5.55713 1.94232M14.5571 3.44231V1.94232"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
