/* eslint-disable react/prop-types */
import React from 'react';

const PLUS = props => {
  const { fill = 'none', stroke = '#adabbc' } = props;
  return (
    <g
      id="Artboard_27"
      data-name="Artboard – 27"
      clipPath="url(#clip-Artboard_27)"
    >
      <g
        id="Group_1084"
        data-name="Group 1084"
        transform="translate(6.468 6.225)"
      >
        <line
          id="Line_12"
          data-name="Line 12"
          y1="9.479"
          transform="translate(4.488 0)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_13"
          data-name="Line 13"
          x2="8.977"
          transform="translate(0 4.739)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  );
};
export default PLUS;
