import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Loader, Icon, Heading } from '../../../../common';
import { AddUser } from '../AddUser';
import { Loading } from '../Loading';
import { userSelector } from '../../../../state/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../../state/user/reducers';
import { media } from '../../../../utils/styles';

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MediaRender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding: 0;
    margin: 0;
    color: #778092;
  }
  span.small {
    display: block;

    ${media.md`
    display: none;
  `}
  }
  span.large {
    display: none;
    ${media.md`
      display: block;
  `}
  }
`;

const Controls = ({ callback, isOpen }) => {
  const { loading } = userSelector;
  const dispatch = useDispatch();
  const isLoading = useSelector(loading);

  const onRefresh = () => {
    dispatch(fetchUsers());
  };

  return (
    <ControlsContainer>
      <Heading>Users</Heading>
      <div style={{ display: 'flex' }}>
        <AddUser />
        <Button variant="OUTLINE" onClick={callback} isActive={isOpen}>
          <MediaRender>
            <span className="large">Choose Date Range</span>
            <span className="small">
              <Icon name="CALENDAR" stroke="#778092" size={16} />
            </span>
          </MediaRender>
        </Button>
        <Button variant="OUTLINE" onClick={onRefresh} horizontalPush={'8px'}>
          <Icon name="REFRESH" size={20} fill="#778092" />
        </Button>
        {isLoading && <Loading />}
      </div>
    </ControlsContainer>
  );
};

export { Controls };
