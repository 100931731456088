import styled, { css } from 'styled-components';
import { includes } from 'ramda';

import {
  verticalPullFn,
  verticalPushFn,
  maxHeightFn,
  bgColorFn,
  paddingFn,
  widthFn,
} from '../../utils/styles';

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  background: white;
  border: 1px solid #e3e5eb;
  ${verticalPullFn};
  ${verticalPushFn};
  ${bgColorFn};
  ${maxHeightFn};
  ${widthFn};
  border-radius: 8px 8px 0px 0px;
  ${props =>
    props.isActive &&
    css`
      max-height: 500px;
    `};
`;

// TODO: REMOVE THIS RESET FUNCTION
const AccordionHeader = styled.div`
  display: block;
  font-size: 16px;
  color: black;
  width: 100%;
  text-align: left;
  line-height: 16px;
  padding: 16px;
  box-sizing: border-box;
  user-select: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const ExpandContainer = styled.div`
  margin-top: 0px;
  margin-right: 4px;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  ${props =>
    props.isActive &&
    css`
      margin-bottom: 4px;
    `}
  ${props =>
    props.maxHeight &&
    css`
      max-height: max-content;
    `}
`;

const Expand = styled.div.attrs({ className: 'accordion-expand' })`
  max-height: 0;
  height: 0;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  overflow-y: auto;
  transition: all 0.1s ease-in-out;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
  ${paddingFn};
  ${props => {
    const { isActive, maxHeight } = props;
    const hasUnit = includes('px')(maxHeight);
    const withUnit = hasUnit ? maxHeight : `${maxHeight}px`;
    if (isActive) {
      return css`
        height: 100%;
        max-height: ${withUnit};
      `;
    }
  }}
`;

const ellipses = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const Title = styled.div`
  ${ellipses};
  font-size: ${props => props.size};
  font-weight: 500;
  ${props =>
    props.position === 'LEFT' &&
    css`
      margin-left: 24px;
    `}
  ${props =>
    props.position === 'RIGHT' &&
    css`
      margin: 0;
    `}
`;

export {
  Container,
  IconContainer,
  AccordionHeader,
  Title,
  Expand,
  ExpandContainer,
};
