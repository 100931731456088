/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M3 3L3 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10L17 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.35355 10.3536L9.14645 13.1464C9.46143 13.4614 10 13.2383 10 12.7929L10 7.20711C10 6.76166 9.46143 6.53857 9.14645 6.85355L6.35355 9.64645C6.15829 9.84171 6.15829 10.1583 6.35355 10.3536Z"
        fill={stroke}
      />
    </svg>
  );
};

export default IconPath;
