/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = (props) => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.7742 15.5V12H8.16285C5.94005 12 3.89323 13.1714 2.82053 15.0574L2 16.5V11.75C2 9.12665 4.19525 7 6.90323 7H10.7742V3.5L18 9.5L10.7742 15.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
