/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3 10.9999V15.9999C3 16.5522 3.44772 16.9999 4 16.9999H16C16.5523 16.9999 17 16.5522 17 15.9999V10.9999"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6L10 14"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.64645 3.35355L6.85355 6.14645C6.53857 6.46143 6.76165 7 7.20711 7L12.7929 7C13.2383 7 13.4614 6.46143 13.1464 6.14645L10.3536 3.35355C10.1583 3.15829 9.84171 3.15829 9.64645 3.35355Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
