/* eslint-disable react/prop-types */
import React from 'react';

const Messages = props => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.73333 14.9011C5.53322 14.9011 5.34141 14.981 5.20057 15.1232L3.16675 17.1758C3.02134 17.3226 2.75 17.2332 2.75 16.9932V3.50477C2.75 3.35754 2.86644 3.25 2.99556 3.25H17.0044C17.1336 3.25 17.25 3.35754 17.25 3.50477V14.6463C17.25 14.7935 17.1336 14.9011 17.0044 14.9011H5.73333Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="5.75"
        y1="10.25"
        x2="11.25"
        y2="10.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="5.75"
        y1="7.25"
        x2="14.25"
        y2="7.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Messages;
