import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../utils/services';

const initialState = {
  loading: false,
  range: {
    start: '',
    end: '',
  },
  error: '',
};

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async (payload = '', { rejectWithValue }) => {
    const snapshot = await db.collection('settings').get();
    const data = snapshot.docs.map(doc => doc.data());
    if (data) {
      return data[0];
    } else {
      rejectWithValue('No settings found');
    }
  },
);

export const addStartRange = createAsyncThunk(
  'settings/addStartRange',
  async (data, { dispatch }) => {
    await db.collection('settings').doc('range').update({
      start: data,
    });
    dispatch(fetchSettings());
  },
);

export const addEndRange = createAsyncThunk(
  'settings/addEndRange',
  async (data, { dispatch }) => {
    await db.collection('settings').doc('range').update({
      end: data,
    });
    dispatch(fetchSettings());
  },
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSettings.pending]: state => {
      state.loading = true;
    },
    [fetchSettings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.range = payload;
      state.error = '';
    },
    [fetchSettings.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [addStartRange.pending]: state => {
      state.loading = true;
    },
    [addStartRange.fulfilled]: state => {
      state.loading = false;
    },
    [addStartRange.rejected]: state => {
      state.loading = false;
    },
    [addEndRange.pending]: state => {
      state.loading = true;
    },
    [addEndRange.fulfilled]: state => {
      state.loading = false;
    },
    [addEndRange.rejected]: state => {
      state.loading = false;
    },
  },
});

export default settingsSlice.reducer;
