/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 18C7.86312 18 5.85416 17.1678 4.34313 15.6569C2.83216 14.1458 2 12.1369 2 10C2 7.86312 2.83216 5.85416 4.34313 4.34313C5.85416 2.83216 7.86312 2 10 2C12.1369 2 14.1458 2.83216 15.6569 4.34313C17.1678 5.85416 18 7.86312 18 10C18 12.1369 17.1678 14.1458 15.6569 15.6569C14.1458 17.1678 12.1369 18 10 18ZM10 3.25C6.27803 3.25 3.25 6.27803 3.25 10C3.25 13.722 6.27803 16.75 10 16.75C13.722 16.75 16.75 13.722 16.75 10C16.75 6.27803 13.722 3.25 10 3.25ZM12.9293 11.3809C12.6381 11.1955 12.2517 11.2814 12.0665 11.5726C12.0588 11.5847 11.2809 12.7832 9.96875 12.7832C8.65656 12.7832 7.87875 11.5847 7.87103 11.5726C7.68572 11.2814 7.29944 11.1956 7.00822 11.3809C6.717 11.5662 6.63116 11.9525 6.81647 12.2437C6.86297 12.3168 7.97809 14.0332 9.96875 14.0332C11.9594 14.0332 13.0745 12.3168 13.121 12.2437C13.3063 11.9525 13.2205 11.5662 12.9293 11.3809ZM7.25 7.15625C7.68147 7.15625 8.03125 7.50603 8.03125 7.9375C8.03125 8.36897 7.68147 8.71875 7.25 8.71875C6.81853 8.71875 6.46875 8.36897 6.46875 7.9375C6.46875 7.50603 6.81853 7.15625 7.25 7.15625ZM11.9375 7.9375C11.9375 8.36897 12.2873 8.71875 12.7188 8.71875C13.1502 8.71875 13.5 8.36897 13.5 7.9375C13.5 7.50603 13.1502 7.15625 12.7188 7.15625C12.2873 7.15625 11.9375 7.50603 11.9375 7.9375Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
