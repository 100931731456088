/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.97128 8.49005V11C6.9712 11.267 7.02454 11.5312 7.12816 11.7771C7.23178 12.0231 7.38359 12.2459 7.57464 12.4322C7.76568 12.6186 7.9921 12.7649 8.24055 12.8624C8.48901 12.9599 8.75446 13.0067 9.02128 13C9.55172 13 10.0604 12.7893 10.4355 12.4143C10.8106 12.0392 11.0213 11.5305 11.0213 11C10.9241 9.95807 10.4386 8.99073 9.66128 8.29005C8.87773 7.50789 8.36596 6.49486 8.20128 5.40005C8.05128 4.09005 8.73128 2.00005 10.3313 2.00005C10.4432 1.99842 10.5521 2.03681 10.6383 2.10831C10.7244 2.1798 10.7822 2.27972 10.8013 2.39005C11.0151 3.37207 11.412 4.30501 11.9713 5.14005C12.5828 5.96489 13.2837 6.71949 14.0613 7.39005C14.8157 8.12418 15.3995 9.01522 15.7713 10C16.082 10.9254 16.1649 11.912 16.0131 12.8762C15.8613 13.8404 15.4792 14.7537 14.8992 15.5388C14.3192 16.3239 13.5585 16.9575 12.6815 17.386C11.8044 17.8144 10.837 18.0251 9.86128 18C9.27259 17.9739 8.69037 17.8662 8.13128 17.68C7.33104 17.4178 6.59498 16.99 5.97097 16.4245C5.34695 15.859 4.84897 15.1685 4.5094 14.3978C4.16983 13.6272 3.99629 12.7937 4.00006 11.9516C4.00383 11.1095 4.18483 10.2776 4.53128 9.51005C4.86128 8.85005 5.70128 7.28005 6.63128 7.86005C6.73798 7.92629 6.82549 8.01928 6.88513 8.1298C6.94478 8.24031 6.97448 8.36451 6.97128 8.49005Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
