import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Modal, Icon, ErrorList } from '../../../../common';
import { addUser } from '../../../../state/user/reducers';
import { userSelector } from '../../../../state/user/selectors';

import { useDispatch, useSelector } from 'react-redux';

import { media } from '../../../../utils/styles';

const AddUserContainer = styled.form`
  padding: 0;
  margin: 0;
  display: flex;
  padding: 24px;
  flex-direction: column;
`;

const MediaRender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding: 0;
    margin: 0;
    color: #778092;
  }
  span.small {
    display: block;

    ${media.md`
    display: none;
  `}
  }
  span.large {
    display: none;
    ${media.md`
      display: block;
  `}
  }
`;

const AddUser = () => {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');

  const { loading } = userSelector;
  const isLoading = useSelector(loading);
  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState(isLoading);

  const [errors, setErrorList] = useState([]);

  const removeItem = name => {
    setErrorList(errors.filter(item => item.name !== name));
  };

  const onSubmitUser = e => {
    e.preventDefault();
    if (
      !firstname ||
      firstname.length <= 0 ||
      !lastname ||
      lastname.length <= 0
    ) {
      setErrorList([
        {
          name: 'error',
          message:
            'An error has occured, make sure you have entered a first and last name',
        },
      ]);
      return;
    }
    if (
      !firstname ||
      firstname.length <= 2 ||
      !lastname ||
      lastname.length <= 2
    ) {
      setErrorList([
        {
          name: 'error',
          message:
            'Make sure you have entered enough characters for each user.',
        },
      ]);
      return;
    }
    dispatch(
      addUser({
        uid: firstname,
        firstname: firstname,
        lastname: lastname,
        pin: '0000',
      }),
    );
    setModalOpen(false);
    setErrorList([]);
  };

  const onChangeUserName = e => {
    const { value } = e.target;
    setFirstName(value);
  };

  const onChangeUserLastName = e => {
    const { value } = e.target;
    setLastName(value);
  };

  const onHandleCallback = () => {
    setErrorList([]);
    setModalOpen(false);
  };

  const renderAddUser = (
    <Modal title="Add User" callback={() => onHandleCallback()}>
      <AddUserContainer onSubmit={onSubmitUser}>
        <Input
          type="text"
          placeholder="First Name"
          onChange={onChangeUserName}
          value={firstname}
        />
        <Input
          verticalPush={16}
          type="text"
          placeholder="Last Name"
          onChange={onChangeUserLastName}
          value={lastname}
        />

        {errors && errors.length > 0 && (
          <div style={{ marginTop: '16px' }}>
            <ErrorList
              haystack={errors}
              callback={removeItem}
              hasClose={true}
              needles={['error']}
            />
          </div>
        )}

        <Button
          variant="PRIMARY"
          style={{ marginTop: '16px', justifyContent: 'center' }}
          onClick={onSubmitUser}
        >
          Create User
        </Button>
      </AddUserContainer>
    </Modal>
  );

  return (
    <>
      <Button
        variant="OUTLINE"
        style={{
          marginRight: '8px',
        }}
        onClick={() => setModalOpen(true)}
      >
        <MediaRender>
          <span className="large">Add User</span>
          <span className="small">
            <Icon name="CREATE" stroke="#778092" size={12} />
          </span>
        </MediaRender>
      </Button>
      {isModalOpen && renderAddUser}
    </>
  );
};

export { AddUser };
