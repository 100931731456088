import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { addUserImage } from '../../../../state/user/reducers';

import { usePrevious } from '../../../../utils/hooks';
import { authSelector } from '../../../../state/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../../../../common';

const ImageDisplay = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
`;

const ImageDisplayText = styled.span`
  font-size: 16px;
  padding: 0;
  line-height: 24px;
  margin-right: 16px;
`;

const ImageUploadIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #f7f8fa;
  border-radius: 50%;
  margin: 8px 0px;
`;

const ImageUploadContent = styled.span`
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 10%;
  color: #121b2d;
`;

const UploadButtonLabel = styled.label`
  input {
    display: none;
  }
  &.custom-file-upload {
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    cursor: pointer;
    outline: none;
    height: 160px;
    width: 100%;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    margin-right: 12px;
    background: #ffffff;
    border: 1px solid #e3e5eb;
    border-radius: 8px;
    ${props =>
      props.imageSrc &&
      css`
        background-image: ${`url(${props.imageSrc})`};
        background-color: transparent;
        background-size: 80px 80px;
        background-repeat: no-repeat;
        object-fit: cover;
        background-position: center center;
      `}
    ${props =>
      props.isActive &&
      css`
        border: 1px solid #ff5a45;
      `}
  }

  .file-preview {
    margin: 0 10px;
  }
`;

const ImageCropperButton = ({
  onChange = () => {},
  imageName = '',
  image = null,
}) => (
  <UploadButtonLabel className="custom-file-upload" imageSrc={image}>
    {!image && (
      <ImageUploadIcon>
        <Icon name="IMAGE_CROP" size={20} />
      </ImageUploadIcon>
    )}
    <ImageUploadContent>
      {image ? `Change Image` : `Add Image`}
    </ImageUploadContent>
    <input type="file" accept="image/*" onChange={onChange} />
  </UploadButtonLabel>
);

const Uploader = ({ uid, image: userImage = null }) => {
  const dispatch = useDispatch();
  const { loading } = authSelector;

  const isLoading = useSelector(loading);
  const prevLoading = usePrevious(isLoading);

  useEffect(() => {
    if (isLoading !== prevLoading) {
      setImage('');
    }
  }, [isLoading]);

  const [image, setImage] = useState('');

  const upload = () => {
    if (image === null || image === '') return;
    dispatch(addUserImage({ image, uid }));
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <ImageCropperButton
        image={userImage}
        imageName={image && image.name}
        onChange={e => setImage(e.target.files[0])}
      />
      {image && (
        <ImageDisplay>
          {image.name && <ImageDisplayText>{image.name}</ImageDisplayText>}
          <Button variant="SUCCESS" onClick={upload}>
            Save Image
          </Button>
        </ImageDisplay>
      )}
    </div>
  );
};

export { Uploader };
