/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.45455 2.75H14.5455C15.9258 2.75 17.1683 3.93907 17.25 5.47339V14.5455C17.25 16.0403 16.0403 17.25 14.5455 17.25H5.45455C3.95967 17.25 2.75 16.0403 2.75 14.5455V5.45455C2.75 3.95967 3.95967 2.75 5.45455 2.75Z"
        stroke="#121B2D"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39596 10.5713C8.52414 10.5713 8.64598 10.627 8.72986 10.7239L10.4339 12.693L11.4587 11.5089C11.5425 11.412 11.6644 11.3563 11.7926 11.3563C11.9207 11.3563 12.0426 11.412 12.1265 11.5089L14.5041 14.2564C14.6172 14.387 14.6436 14.5717 14.5718 14.7289C14.4999 14.886 14.343 14.9869 14.1702 14.9869H5.33903C5.16621 14.9869 5.00927 14.886 4.93743 14.7289C4.86559 14.5717 4.89204 14.387 5.00513 14.2564L8.06207 10.7239C8.14594 10.627 8.26779 10.5713 8.39596 10.5713Z"
        fill="#121B2D"
      />
      <path
        d="M8.76946 7.53902C8.76946 8.55863 7.9429 9.38518 6.9233 9.38518C5.9037 9.38518 5.07715 8.55863 5.07715 7.53902C5.07715 6.51942 5.9037 5.69287 6.9233 5.69287C7.9429 5.69287 8.76946 6.51942 8.76946 7.53902Z"
        fill="#121B2D"
      />
    </svg>
  );
};

export default IconPath;
