import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const MenuBodyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  ${props =>
    props.isExpanded &&
    css`
      margin-top: 60px;
    `};
`;

const MenuGroup = styled.ul`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const MenuItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 13px;
  display: flex;
  color: white;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  background: #4b658d;
  margin-top: 4px;
  transition: all 0.2s ease-in-out;
  ${props =>
    props.isActive &&
    css`
      background: #0d2241;
      .MenuText {
        color: white;
      }
    `}
  &:hover {
    background: #0d2241;
    color: white;
    .MenuText {
      color: white;
    }
  }
`;

const MenuText = styled.span`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  /* color: #121b2d; */
  color: white;
`;

const MenuIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  .isExpanded & {
    display: none;
  }
`;

const MenuCustomIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
`;

const MenuCloseContainer = styled.div`
  padding-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e3e5eb;
`;

const MenuClose = styled.button`
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
`;

export {
  MenuClose,
  MenuBodyContainer,
  MenuCloseContainer,
  MenuText,
  MenuGroup,
  MenuIcon,
  MenuItem,
  MenuCustomIcon,
};
