import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const MenuFooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  bottom: 16px;
  margin-top: auto;
  bottom: 0;
  ${props => props.isExpanded && css``}
`;

const FooterGroup = styled.ul`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const FooterItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 13px;
  display: flex;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  background: transparent;
  transition: all 0.2s ease-in-out;
  background-color: rgba(75, 101, 141, 0);
  .FooterText {
    transition: all 0.2s ease-in-out;
  }
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    background-color: rgba(75, 101, 141, 0.3);
    .FooterText {
      color: #4b658d;
    }
    svg {
      path {
        stroke: #4b658d;
      }
    }
  }
`;

const FooterText = styled.span`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #121b2d;
`;

const FooterIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  .isExpanded & {
    display: none;
  }
`;

// const LogoContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-sizing: border-box;
//   transition: all 0.3s ease-in-out;
//   .isExpanded & {

//   }
// `;

export { MenuFooterContainer, FooterText, FooterGroup, FooterIcon, FooterItem };
