/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = 'none' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <circle cx="10" cy="10" r="7.25" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M5.19995 14.8003L15.2 5.20026"
        stroke="#A9AEB9"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPath;
