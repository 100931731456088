/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#ACB5C7' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10.25" r="2.25" fill={stroke} />
      <circle cx="3.25" cy="10.25" r="2.25" fill={stroke} />
      <circle cx="16.75" cy="10.25" r="2.25" fill={stroke} />
    </svg>
  );
};

export default IconPath;
