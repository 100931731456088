/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = 'none' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.01755 18H10.9825C11.1696 18 11.3567 17.8592 11.3567 17.7654L11.9649 15.9355L12.6667 15.654L14.3509 16.4986C14.538 16.5924 14.7719 16.5455 14.9123 16.4047L16.3158 14.9971C16.4562 14.8563 16.5029 14.6217 16.4094 14.4341L15.5673 12.7449L15.848 12.0411L17.6725 11.478C17.8596 11.4311 18 11.2434 18 11.0557V9.08507C18 8.89736 17.8596 8.70969 17.6725 8.66277L15.848 8.0528L15.5673 7.34899L16.4094 5.65983C16.5029 5.47216 16.4562 5.23757 16.3158 5.09679L14.9123 3.68915C14.7719 3.54838 14.5848 3.50145 14.3977 3.5953L12.7135 4.43987L12.0117 4.15835L11.4503 2.32845C11.4035 2.14078 11.2164 2 11.0292 2H9.06433C8.87718 2 8.69006 2.14078 8.64327 2.32845L8.03509 4.15835L7.33333 4.43987L5.64912 3.59527C5.462 3.50145 5.22809 3.54835 5.08773 3.68912L3.68421 5.09676C3.54385 5.2375 3.49706 5.4252 3.59064 5.61287L4.43273 7.30203L4.15203 8.00585L2.32748 8.56889C2.14036 8.61581 2 8.80351 2 8.99118V10.9619C2 11.1496 2.14036 11.3372 2.32748 11.3842L4.15203 11.9941L4.43273 12.6979L3.59064 14.3871C3.49709 14.5748 3.54385 14.8094 3.68421 14.9501L5.08773 16.3577C5.22806 16.4985 5.41521 16.5454 5.60233 16.4516L7.28655 15.607L7.9883 15.8885L8.59648 17.6716C8.64327 17.8592 8.83043 18 9.01755 18ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89544 8 8.00001 8.89543 8.00001 10C8.00001 11.1046 8.89544 12 10 12Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPath;
