/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.5 7V2M18 4.5H13"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.5" cy="7.5" r="2.75" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M15 18.2467V16.2461C15 14.5892 13.8359 13.5 12.4 13.5H4.6C3.16406 13.5 2 14.5892 2 16.2461V18.2467"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
