import { createSelector } from '@reduxjs/toolkit';

const selectUserState = state => state.user;

const loading = createSelector(selectUserState, slice => slice.loading);
const list = createSelector(selectUserState, slice => slice.list);
const error = createSelector(selectUserState, slice => slice.error);

export const userSelector = {
  loading,
  list,
  error,
};
