/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="1.75"
        y="1.75"
        width="12.3192"
        height="12.3192"
        rx="1.25"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M4.61731 16V16.4885C4.61731 17.5931 5.51274 18.4885 6.61731 18.4885H16.25C17.3546 18.4885 18.25 17.5931 18.25 16.4885V7.48853C18.25 6.38396 17.3546 5.48853 16.25 5.48853H16"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconPath;
