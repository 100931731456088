import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Button } from '../../../../common';
import { removeUser } from '../../../../state/user/reducers';
import { media } from '../../../../utils/styles';

import styled from 'styled-components';

const MediaRender = styled.div`
  span.small {
    display: block;
    ${media.md`
    display: none;
  `}
  }
  span.large {
    display: none;
    ${media.md`
      display: block;
  `}
  }
`;

const RemoveUser = props => {
  const { uid } = props;
  const dispatch = useDispatch();
  const onRemove = uid => dispatch(removeUser(uid));

  return (
    <Button
      variant="WARNING"
      style={{
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '8px',
      }}
      onClick={() => onRemove(uid)}
    >
      <MediaRender>
        <span
          className="large"
          style={{ marginRight: '8px', lineHeight: '21px' }}
        >
          Remove User
        </span>
        <span
          className="small"
          style={{ marginRight: '8px', lineHeight: '21px' }}
        >
          Remove
        </span>
      </MediaRender>
      <Icon name="REMOVE" stroke="white" size={20} />
    </Button>
  );
};

export { RemoveUser };
