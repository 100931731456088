/* eslint-disable react/prop-types */
import React from 'react';

const Home = props => {
  const { fill = 'none', stroke = '#ADABBC' } = props;
  return (
    <g id="Artboard_15" data-name="home">
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.5,2.9l8.1,5L9.5,2.9z"
      />
      <line
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="1.9"
        y1="8"
        x2="9.5"
        y2="2.9"
      />
      <line
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="4"
        y1="6.6"
        x2="4"
        y2="15.6"
      />
      <line
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="15.4"
        y1="6.6"
        x2="15.4"
        y2="15.6"
      />
    </g>
  );
};

export default Home;
