import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9', fill = 'none' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill}>
      <path
        d="M6.5 3.5H5.5C4.39543 3.5 3.5 4.39543 3.5 5.5L3.5 16.5C3.5 17.6046 4.39543 18.5 5.5 18.5H14.5C15.6046 18.5 16.5 17.6046 16.5 16.5V5.5C16.5 4.39543 15.6046 3.5 14.5 3.5H13.5"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M7.5 4.5H10.2727H12.5C12.7761 4.5 13 4.27614 13 4V3C13 2.72386 12.7761 2.5 12.5 2.5H12.4376C12.1069 2.5 11.7976 2.33654 11.6114 2.06334L11.3761 1.71833C11.283 1.58173 11.1284 1.5 10.963 1.5H9.0388C8.83577 1.5 8.6529 1.62277 8.57603 1.81068L8.54823 1.87863C8.39448 2.25446 8.02874 2.5 7.62268 2.5H7.5C7.22386 2.5 7 2.72386 7 3V4C7 4.27614 7.22386 4.5 7.5 4.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12L9 13L12.5 9.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
