/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = 'none', stroke = '#a6adb6' } = props;
  return (
    <g fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth="1.5">
      <path d="M7.273 14.3h2.435" />
      <path d="M4.5 8.338h7.981" />
      <g>
        <path d="M11.646 11.343l7.561 7.561" />
        <path d="M19.208 11.343l-7.561 7.561" />
      </g>
      <path d="M.821 2.36h15.67" />
    </g>
  );
};

export default IconPath;
