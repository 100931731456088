/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.3738 9.07506C13.1828 9.28585 13.1274 9.58658 13.2309 9.85157C13.4693 10.462 13.6005 11.1269 13.6005 11.8243C13.6005 14.8206 11.1715 17.2496 8.17524 17.2496C5.17897 17.2496 2.75 14.8206 2.75 11.8243C2.75 10.5702 3.02677 9.51894 3.54124 8.70424C4.05045 7.89788 4.81891 7.28007 5.88807 6.92149C5.90049 6.91732 5.91279 6.91284 5.92497 6.90803L15.9748 2.94095L17.0864 4.97887L13.3738 9.07506Z"
        stroke="#A9AEB9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="12" r="2.2" stroke={stroke} strokeWidth="1.6" />
    </svg>
  );
};

export default IconPath;
