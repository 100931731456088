/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.8367 3.10259C16.7151 2.99805 16.5471 2.97098 16.4001 3.03258L3.27489 8.63299C3.11302 8.70206 3.00451 8.86728 3.00014 9.05396C2.99576 9.24064 3.09551 9.41145 3.25477 9.48985L7.37521 11.5191L16.5629 3.46568L9.12524 12.3806V17L12.2298 13.9095L14.6291 15.0903C14.6877 15.1183 14.7507 15.1332 14.8128 15.1332C14.8828 15.1332 14.9537 15.1145 15.0167 15.0791C15.1383 15.0109 15.2215 14.8868 15.2442 14.743L16.9942 3.54222C17.0196 3.37701 16.9601 3.20806 16.8367 3.10259Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
