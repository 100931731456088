import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../../../../common';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 42, 67, 0.24);
  display: block;
  z-index: 9;
`;

const BladeContainer = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: -400px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  ${props =>
    props.isActive &&
    css`
      right: 0;
    `}
`;

const BladeButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const BladeWrapper = styled.div`
  background: white;
  border-radius: 6px;
  height: 100%;
  padding: 16px 4px 16px 16px;
  box-shadow: 0px 2px 4px rgb(28 17 44 / 4%), 0px 5px 12px rgb(28 17 44 / 3%);
`;

const BladeBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 4px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
  height: calc(100% - 32px);
`;

const BladeHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
`;

const BladeInner = styled.div`
  padding: 8px;
  /* height: calc(100% - 48px); */
  height: 100%;
`;

const BladeTitle = styled.h3`
  font-size: 20px;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Blade = ({ title = '', children, isActive, onClose = () => {} }) => (
  <>
    <BladeContainer isActive={isActive} className="blade">
      <BladeInner>
        <BladeWrapper>
          <BladeHeader>
            <BladeButton onClick={() => onClose(false)}>
              <Icon name="CLOSE" size={20} />
            </BladeButton>
            <BladeTitle>{title}</BladeTitle>
          </BladeHeader>
          <BladeBody>{children}</BladeBody>
        </BladeWrapper>
      </BladeInner>
    </BladeContainer>
    {isActive && <Overlay onClick={() => onClose(false)} />}
  </>
);

export { Blade };
