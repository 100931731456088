/* eslint-disable no-underscore-dangle */
import React from 'react';
import { TextParagraph, TextLink } from './Variant.styled';
import { toUpper, keys, includes } from 'ramda';
import { Variant } from './Variant';

const Text = ({ type = 'P', children, ...props }) => {
  const config = {
    ['P']: <TextParagraph {...props}>{children}</TextParagraph>,
    ['SPAN']: <Variant {...props}>{children}</Variant>,
    ['LINK']: <TextLink {...props}>{children}</TextLink>,
  };

  if (!includes(toUpper(type))(keys(config))) {
    return <TextParagraph {...props}>{children}</TextParagraph>;
  }

  return config[toUpper(type)];
};

export { Text };
