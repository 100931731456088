import React, { useReducer, useMemo } from 'react';
import styled from 'styled-components';

import Reducer, { InitialState } from './Reducer';

import Context from './Context';

const ProviderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const context = useMemo(() => [state, dispatch], [state, dispatch]);
  return (
    <Context.Provider value={context}>
      <ProviderContainer>{children}</ProviderContainer>
    </Context.Provider>
  );
};

export default Provider;
