import styled, { css } from 'styled-components';

const resetStyles = css`
  padding: 0;
  margin: 0;
  font-style: normal;
  color: #29323d;
  line-height: 100%;
`;

const Heading = styled.h1.attrs({ className: 'primary' })`
  ${resetStyles};
  font-size: 26px;
  font-weight: 700;
  line-height: 52px;
`;

export { Heading };
